@use "/src/utils/colors";

#user-management-page {
  display: flex;
  flex-direction: column;
  height: 80%;
  margin-left: 1.5%;

  @media (max-width: 1080px) {
    margin-left: 0px;
  }

  .control-wrapper {
    z-index: 1;
    display: flex;
    width: 100%;
    position: relative;

    .add-new-container {
      padding-left: 13px;
      width: 50%;
      margin-top: 20px;
      margin-right: 15px;
      right: 0;

      a {
        text-decoration: none;
      }

      div.add-btn {
        border: 1px solid colors.$blue5;
        height: 35px;
        border-radius: 20px;
        display: flex;
        width: fit-content;
        padding: 0 10px;
        transition: 150ms ease-in-out;
        font-weight: 400;
        align-items: center;
        padding-right: 13px;
        background: colors.$blue5;
        position: absolute;
        right: 0;

        span,
        svg {
          color: white;
        }

        svg {
          color: white;
          font-size: 20px;
        }

        &:hover {
          cursor: pointer;
          opacity: 0.9;
          transform: scale(1.1);
        }

        &:active {
          cursor: pointer;
        }
      }
    }
  }

  .MuiCheckbox-root {
    padding: 0;
    // &.Mui-checked svg {
    //   color: colors.$blue5 !important;
    // }
  }

  #um-list-title {
    // font-weight: 500;
    // font-stretch: normal;
    // font-style: normal;
    // letter-spacing: 0.32px;
    // color: colors.$blue4;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 2px solid colors.$grey5;
    margin-bottom: 10px;
    padding: 10px 0;

    .subtitle {
      font-style: italic;
      font-size: 0.9em;
      color: grey;
    }

    // .title {
    //   width: 50%;
    //   padding-top: 20px;
    // }

    .search-bar {
      fieldset {
        border-radius: 20px;
        padding: 0px;
        color: colors.$grey5;
        padding-right: 2px !important;
      }

      svg {
        color: #fff;
        background-color: colors.$blue5;
        border-radius: 20px;
        padding: 4px;
        margin: 2px;
      }
    }

    .vertical-divider {
      width: 2px;
      height: 30px;
      background-color: colors.$grey5;
      margin-left: auto;
      margin-right: 10px;
    }
  }

  #um-batch-actions-container {
    display: flex;
    align-items: center;
    > * {
      margin-right: 20px;
    }

    button.MuiButton-root {
      background-color: colors.$blue5;
      color: white;
      font-weight: normal !important;
      font-size: 0.8em !important;
      transition: background-color 150ms;
      &:hover {
        background-color: colors.$blue1;
      }

      &.Mui-disabled {
        background-color: colors.$grey5;
      }
    }
  }

  #um-header-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    > div {
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.26px;
      //padding-left: 10px;
    }

    div.sortable {
      width: fit-content;
      cursor: pointer;
      display: flex;
      justify-content: center;
      svg {
        font-size: 18px;
        color: #a19090;
        margin: 0px;
        margin-left: 5px;
      }

      svg.selected {
        color: colors.$blue5;
      }

      svg.reverse {
        transform: scale(1, -1);
      }
    }
  }

  #um-list-container {
    background: white;
    border-radius: 10px;
    padding: 40px;
    padding-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px;
    margin-top: 10px;

    @media (max-width: 1080px) {
      padding: 20px;
      margin-top: 30px;
    }

    .spinner {
      margin: 50px auto;
      text-align: center;

      .MuiCircularProgress-colorPrimary {
        color: colors.$blue4 !important;
      }
    }

    .no-users,
    .spinner {
      font-size: 1.5em;
      margin: 50px auto;
      text-align: center;
      color: colors.$blue4 !important;
    }

    td {
      .edit-action-container {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        > * {
          margin-right: 5px;
        }

        button {
          svg {
            color: colors.$blue5;
            transition: transform 150ms;
          }
          &:hover {
            svg {
              color: colors.$blue1;
              transform: scale(1.2);
            }
          }
        }
      }
      div.sync-icon-container {
        text-align: center;
        *:hover {
          cursor: default;
        }
      }
    }
  }

  .MuiTableContainer-root table tbody tr td:last-child .actions {
    min-width: 120px;
    padding-left: 10px;
  }

  th.MuiTableCell-root {
    padding: 2px 8px;
  }
  td.MuiTableCell-root {
    padding: 0;
  }
}

#role-dialog,
#dashboard-dialog,
#report-access-dialog {
  .subtitle {
    padding: 0 30px 10px 30px;
    font-style: italic;
    font-size: 0.9em;
    color: grey;
  }
  label.MuiFormControlLabel-root {
    width: fit-content;
  }
}

#report-access-dialog {
  .dialog-content > p {
    font-style: italic;
  }

  .MuiFormGroup-root {
    display: block;
    overflow-y: auto;
    max-height: calc(100vh - 400px);
    > div {
      width: 100%;
    }
  }

  #actions-container {
    .action {
      min-width: 100px !important;
      margin-left: 10px;

      &:not(:disabled) {
        &.red {
          border-color: colors.$red1 !important;
          color: colors.$red1 !important;
          &:hover {
            background-color: rgba(163, 85, 85, 0.1);
          }
        }
      }
    }
  }
}
