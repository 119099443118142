$apexBackgroundColor:"#CFCFCF";
$whiteColor:#FFFFFF;
$tileRedColor: #E35E5A;
$tileOrangeColor: #F8C45A;
$tileBlueColor: #5469CF;
$tilePictonBlueColor: #56A6D2;
$tileAquamarineColor: #5AC09F;
$tielPurpleColor: #9C82F8;
$tielYelloColor: #F2B438;
$blackColor: #142330;
$blueColor: #2C4259;

@mixin sideBarIconStyle {
    position: absolute;
    left: 45.47%;
    right: 45.47%;
    top: 15.72%;
    bottom: 83.59%;
    border: 1.5px solid $blackColor;
}

@mixin sidebaricons{
    color:"#758496";
    border: 1.5px solid #758496;
}

@mixin sidebaricon-active{
    fill:#E9F8FF;
    path{
        stroke: $blackColor;
    }
}

@mixin sideBarItemStyle($fw: 400,$fs: 18px, $lh: 24px, $ls: -0.02em, $color: $blackColor) {
    font-weight: $fw;
    font-size: $fs;
    line-height: $lh;
    letter-spacing: $ls;
    color: $color;
  }