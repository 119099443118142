@use "/src/utils/colors";

#environment-banner {
  position: fixed;
  bottom: 10px;
  right: 30px;
  background-color: colors.$blue5;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px 10px 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px;

  svg {
    font-size: 18px;
    margin-right: 7px;
  }
}
