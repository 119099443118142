@use "/src/utils/colors";

#subscription-form {
  background: white;
  border-radius: 10px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
  margin-top: 10px;
  margin-bottom: 60px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 900px;
  min-width: 600px;
  margin-left: 1.5%;

  @media (max-width: 1080px) {
    margin-left: 0;
  }

  #header-container {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: Rubik;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;

    .report-title {
      width: 100%;
      line-height: 2.19;
      letter-spacing: 0.32px;
      text-align: left;
      color: colors.$blue4;
    }

    .delete {
      border: 1px solid #9a6060;
      //border: none;
      color: #9a6060;
      background-color: white;
      margin-left: auto;
      width: 210px;
      min-width: 210px;
      border-radius: 50px;
      cursor: pointer;
      margin-top: 5px;
      padding-top: 3px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-content: center;

      svg {
        font-size: 20px;
      }
      div {
        font-family: Rubik;
        font-weight: 500;
        margin-top: 4px;
      }

      &:disabled {
        border: 1px solid colors.$grey5;
        background-color: colors.$grey5;
        cursor: not-allowed;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .separator {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .form-wrapper {
    width: 100%;
    color: colors.$blue5;

    svg {
      color: colors.$blue5;
    }

    .form-container {
      margin-top: 20px;
      display: flex;
      flex-grow: 1;
      height: 59vh;
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-color: colors.$blue4 rgba(149, 148, 148, 0.3);
      scrollbar-width: thin;

      @media (max-width: 1080px) {
        height: 60vh;
      }

      .form-row {
        width: 100%;
        padding-top: 0px;
        display: flex;

        .report-filters {
          .item {
            padding-top: 10px;
            word-spacing: 5px;
            overflow-wrap: break-word;
            font-weight: 400;
          }

          .filters-cell {
            width: 100%;
            display: flex;

            a,
            a:hover {
              color: colors.$blue5;
            }

            div {
              svg {
                font-size: 20px;
                display: flex;
                margin-left: 10px;
              }
            }
          }
        }
      }

      .w-100 {
        width: 100% !important;
        min-width: 100% !important;
      }

      .w-70 {
        width: 70%;
      }

      .w-50 {
        width: 50%;
      }

      .w-40 {
        width: 40%;
      }

      .w-25 {
        width: 25%;
      }

      .pr-25 {
        padding-right: 25px;
      }

      .label-cell {
        color: colors.$blue5;
        letter-spacing: 0.28px;
        width: 100%;

        span,
        div {
          font-size: 0.8em;
          color: colors.$blue4;
          font-weight: normal;
        }
      }

      .control-cell {
        width: 100%;
        padding-top: 10px;
        padding-left: 0;
        display: flex;

        label.email {
          width: 130px;
          padding-top: 10px;
          span {
            font-size: 0.8em;
            color: colors.$blue4;
            font-weight: normal;
          }
        }

        .frequency-left {
          width: 17%;
          border-right: 1px solid #e8e8e8;
          padding-left: 10px;
        }

        .frequency-right {
          width: 100%;
          padding-left: 20px;
          letter-spacing: 0.6px;

          div.label {
            padding-top: 10px !important;
          }

          .recurrence-row {
            display: flex;
            padding-top: 13px;
            justify-items: top;

            label {
              min-width: 100px;
              padding-top: 8px;
            }

            .new-line {
              margin-top: -50px;
            }

            .day {
              width: 40px;
              height: 40px;
              border-radius: 100%;
              background-color: rgba(149, 148, 148, 0.7);
              margin-right: 10px;
              color: white;
              text-align: center;
              line-height: 40px;
              cursor: pointer;
              &.chosen {
                background-color: colors.$blue5;
              }
            }

            .radio-buttons-group {
              margin-top: -15px;
            }

            .select {
              width: 65px;
              height: 25px;
              margin-left: 10px;
              margin-right: 10px;
              margin-top: -5px;

              svg {
                margin-top: -6px;
              }
            }

            .exclude-weekends {
              margin-left: 3%;
              display: flex;
              margin-top: -10px;

              @media (max-width: 1080px) {
                margin-left: 0;
              }
            }

            .select-xlarge {
              margin-left: 5px;
              font-family: Rubik;
              letter-spacing: 0.28px;
              text-align: left;
              color: colors.$blue5;
              width: max-content;
              min-width: 200px;
              max-width: 400px;

              svg {
                font-size: 20px;
                color: colors.$blue5;
              }
            }
          }

          .mb-10 {
            margin-bottom: 10px;
          }

          .mb-15 {
            margin-bottom: 15px;
          }

          .monthly-recurrence-container {
            display: flex;
            padding-top: 8px;
          }

          .radio-group {
            display: flex;
            margin-top: 17px;
            font-family: "Rubik";
            margin-bottom: 5px;

            .select-small {
              margin-top: -8px;
              width: 100px;
              margin-left: 10px;
              font-family: Rubik;
              letter-spacing: 0.28px;
              text-align: left;
              color: colors.$blue5;

              svg {
                font-size: 20px;
                color: colors.$blue5;
              }
            }

            .select-medium {
              margin-top: -8px;
              width: 140px;
              margin-left: 10px;
              font-family: Rubik;
              letter-spacing: 0.28px;
              text-align: left;
              color: colors.$blue5;

              svg {
                font-size: 20px;
                color: colors.$blue5;
              }
            }

            .select-large {
              margin-top: -8px;
              width: 160px;
              margin-left: 10px;
              font-family: Rubik;
              letter-spacing: 0.28px;
              text-align: left;
              color: colors.$blue5;

              svg {
                font-size: 20px;
                color: colors.$blue5;
              }
            }
          }

          .run-time-prefix {
            margin-top: 8px;
          }

          .time-input-container {
            border-radius: 5px;
            box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
            background-color: rgba(85, 136, 163, 0.2);
            margin-left: 10px;
            margin-right: 10px;
            //border-bottom: 2px solid colors.$blue4;
            height: fit-content;
            width: 95px;
            min-width: 95px;
            display: flex;
            padding-right: 5px;

            input.time-input {
              background-color: transparent;
              height: 33px;
              width: 45px;
              margin: 0;
              border-radius: 0;
              box-shadow: none;
            }

            svg {
              font-size: 14px;
              transform: rotate(90deg);
              margin-top: 10px;
              cursor: pointer;
            }

            span {
              margin-top: 8px;
              width: 25px;
            }
          }
        }
      }

      input,
      input:focus {
        border: none;
        height: 28px;
        outline: none;
        color: colors.$blue5;
        margin-right: 5px;
        width: 95% !important;
        padding-left: 10px;
        font-family: Rubik;
        border-radius: 5px;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
        background-color: rgba(85, 136, 163, 0.2);
        height: 40px;
      }

      input:disabled {
        background-color: #e8e8e8;
      }

      .input-error {
        border: 1px solid #a04b5a;
      }

      .select {
        font-family: Rubik;
        letter-spacing: 0.28px;
        text-align: left;
        color: colors.$blue5;
        height: 35px;
        padding-top: 5px;
        width: 92%;
        border-radius: 5px;

        svg {
          position: absolute;
          right: 10px;
          top: 12px;
          font-size: 20px;
          color: colors.$blue5;
        }
      }

      .Mui-disabled {
        -webkit-text-fill-color: colors.$blue5;
      }

      .option {
        font-family: Rubik;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        //line-height: 2.92;
        letter-spacing: 0.6px;
        text-align: left;
        color: colors.$blue5;
      }

      .option-narrow {
        font-family: Rubik;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.6px;
        text-align: left;
        color: colors.$blue5;
        margin-top: -4px;
      }

      textarea,
      textarea:focus {
        border: none !important;
        height: 70px;
        outline: none;
        color: colors.$blue5;
        margin-right: 5px;
        padding-left: 10px;
        font-family: Rubik;
        resize: none;
        border-radius: 5px;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
        background-color: rgba(85, 136, 163, 0.2);
        width: 100%;
        padding-top: 10px;
      }

      .small {
        font-size: 0.8em;
        color: colors.$blue4;
        padding-top: 10px;
        padding-left: 5px;
      }

      .small-error {
        font-size: 0.8em;
        color: #a04b5a;
        padding-top: 10px;
        padding-left: 5px;
      }

      .error {
        font-family: Rubik;
        color: #a04b5a;
        padding-top: 12px;
        padding-left: 10px;
      }

      .mt-10 {
        margin-top: 5px;
      }
    }
  }

  .btn-row {
    display: flex;
    width: 100%;
    margin-top: 20px;
    padding-top: 10px;
    //border-top: 2px solid #e8e8e8;

    .left {
      width: 50%;
    }
    .right {
      width: 50%;
      text-align: right;
    }

    button,
    .link {
      font-family: Rubik;
      border: 1px solid colors.$blue2;
      background-color: colors.$blue2;
      height: 40px;
      width: fit-content;
      min-width: 148px;
      border-radius: 100px;
      box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.3);
      transition: 200ms ease-in-out;
      text-transform: uppercase;
      color: #e8e8e8;
      cursor: pointer;
      letter-spacing: 1.4px;
      line-height: 2.5;
      text-decoration: none;

      &:disabled,
      &:disabled:hover {
        border: 1px solid colors.$grey5;
        background-color: colors.$grey5;
        cursor: not-allowed;
      }

      &:hover {
        border: 1px solid colors.$blue1;
        background-color: colors.$blue1;
      }

      div,
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-decoration: none;

        svg {
          padding-right: 5px;
          color: #fff;
        }
      }
    }

    .link {
      padding-left: 10px;
      padding-right: 20px;
    }

    // .link {
    //   margin-left: 40px;
    //   font-family: Rubik;
    //   line-height: 2.5;
    //   letter-spacing: 1.68px;
    //   text-align: left;
    //   color: colors.$grey5;
    //   text-decoration: none;
    //   text-transform: uppercase;
    // }

    // .link:hover {
    //   opacity: 0.8;
    // }
  }

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: colors.$blue5;
  }

  .MuiStepLabel-label {
    color: colors.$blue5 !important;
    font-weight: 400 !important;
    font-family: Rubik !important;
    letter-spacing: 1.68px !important;
  }

  .Mui-active {
    font-weight: 500 !important;
  }

  .export-type{
    .Mui-selected{
      svg{
        fill:'#165CE8'
      }
    }
  }
}

.facility-error {
  margin-left: 100px;
}
