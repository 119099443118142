@use "/src/utils/colors";

#download-container {
  background: white;
  border-radius: 10px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
  padding-top: 20px;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 400px;
  min-width: 50%;
  max-width: 600px;

  .page-title {
    font-weight: 500;
    font-family: Rubik;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.19;
    letter-spacing: 0.32px;
    text-align: left;
    color: colors.$blue4;
  }

  .page-subtitle {
    font-weight: 500;
    font-family: Rubik;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.32px;
    text-align: left;
    color: colors.$blue4;

    span:last-of-type {
      font-weight: normal;
    }
  }

  td.failed {
    background-color: colors.$grey1 !important;
  }

  .rs-cell {
    .download-container {
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      &:not(.failed) {
        cursor: pointer;
      }

      .failed-tooltip {
        position: absolute;
        left: 5px;
      }

      img {
        width: 32px;
        height: 32px;

        &:not(.failed) {
          transition: transform 150ms ease-in-out;
          &:hover {
            transform: scale(1.1);
          }
          &:active {
            transform: scale(0.9);
          }
        }
      }
    }

    div {
      font-size: 1.1em;
    }
  }

  #download-auto-prompt {
    margin-top: 15px;
  }
}
