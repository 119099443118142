@use "/src/utils/colors";
.export-dialog{
    .MuiDialog-container{
        height: auto;
    }
    .dialog-title{
        border-bottom: 1px solid #D6DADF;
        padding-bottom: 15px;
        color: colors.$blue1;
        font-size: 18px;
        font-family: Open Sans,sans-serif;
        font-style: normal;
        font-weight: 700 !important;
        line-height: 20px;
        letter-spacing: -0.36px;
        text-transform: none;
        min-width: auto;
        .close{
            float: right;
            cursor: pointer;
        }
    }
    .dialog-content{
        min-height: auto;
        .MuiToggleButtonGroup-root button {
            display: flex;
            width: 159px;
            margin: 18px;
            border-left: 1px solid var(--neutral-08, #D6DADF) !important; 
            @media screen and (max-width: 600px) {
                width: 100px;
              }
            
        }
        svg{
            height: 150px;
            path{
                fill:rgb(142, 160, 182)
            }
            @media screen and (max-width: 600px) {
                width: 40px;
                height: 80px;
              }
        }
        .MuiToggleButtonGroup-root button.exp-selected{
            border: 1px solid var(--neutral-01, #58687C) !important;
            background: var(--neutral-10, #F1F4F7);
            border-radius: 4px;
            svg path{
                fill: #165CE8;
            }
          }
    }
    .dialog-footer {
        padding: 0 30px 20px 30px;
        .btn-save:hover{
            background: var(--main-primary-03, #2C4259);
        }
    }
    @media screen and (max-width: 600px) {
    .in .MuiPaper-root {
      left: 0;
     
    }
    .dialog-footer {
      padding: 5px;
    }
  }
}