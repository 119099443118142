.filter-wrapper{
    margin-top: 0 !important;
    .label-container{
        padding-top: 5px !important;
        padding-left: 3px !important;
        text-align: center;
        color: var(--neutral-01, #58687C);
        font-family: Open Sans, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
        @media (max-width: 900px) {
                padding-left: 0px !important;
                text-align: left;
            }
    }
    
    .field-control{
        padding-left: 0 !important;
        font-family: Open Sans, sans-serif;
        .MuiFormControl-root{
            width: -webkit-fill-available;
            @media (max-width: 600px) {
            padding-top: 10px;
            }
            .MuiOutlinedInput-root{
                padding-left: 5px;
                    background: #FFF;
                .MuiInputAdornment-root{
                    margin-right: 0;
                }
            }
        }
    }
    input{
        padding: 8.5px 5px;
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
        
        background: #FFF;
        color: #142330;
        font-weight: 600;
        line-height: 14px; /* 100% */
        letter-spacing: -0.28px;
        &:hover, &:focus-visible{
            outline: 0;
        }
    }
    fieldset{
        border-color:#D6DADF;
        &:hover, &:focus-visible{
            outline: 0;
            border-color: #D6DADF;
        }
    }
}