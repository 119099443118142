@use "/src/utils/colors";
.del-dialog {
    .MuiDialog-container  {
        height: auto;
        .MuiPaper-root{
            border-top: 4px solid;
            border-color: var(--error-color-code, #E2494F);
            border-radius: 8px;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            background: #FFF;
            padding: 10px;
            height: max-content;
            max-width: 500px;
        }
    }

    .del-icon-block{
        text-align: center;
        vertical-align: middle;
        line-height: 1;
    }

    .dialog-content{
        padding: 5px !important;
        min-height: auto !important;  
        .del-heading{
            color: colors.$blue1;
            font-size: 15px;
            font-family: Open Sans, sans-serif;
            font-style: normal;
            font-weight: 700;
            line-height: 1.3;
            letter-spacing: -0.48px;
            text-align: center;
        
            @media screen and (max-width: 600px) {
                line-height: 24px;
                font-size: 18px;
                text-align: center;
            }
        }
        .warning-msg{
            color: var(--neutral-01, #58687C);
            text-align: center;
            font-size: 13px;
            font-family: Open Sans, sans-serif;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.32px;
        }
    }

    .dialog-footer{
        .btn-delete{
            border-radius: 4px;
            border: 1px solid var(--error-color-code, #E2494F);
            background: var(--error-color-code, #E2494F);
            color: #FFF;
            font-size: 14px ;
            font-family: Open Sans, sans-serif;
            font-style: normal;
            font-weight: 600;
            line-height: 14px;
            letter-spacing: -0.36px;
            text-transform: none;
            display: inline-flex;
            height: 40px;
            padding: 12px 16px;
            align-items: center;
            gap: 8px;
        }
    }

   
.MuiStepper-root{
    width: 100%;
    .Mui-active{
        font-weight: 700 !important;
        line-height: 24px !important; /* 171.429% */
    }
    .MuiStepLabel-label{
        color: var(--main-primary-01, #142330);

        /* Para14px/Regular */
        font-family: Open Sans,sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 185.714% */
    
}
    .MuiStepLabel-label.MuiStepLabel-alternativeLabel{
        margin-top: 0;
    }
    .MuiStepConnector-root {
        left: calc(-50% + 4px) !important;
        right: calc(50% + 4px) !important;
    }
}



}
.del-dash-dialog .MuiDialog-container .MuiPaper-root {
    min-width: 400px;
    &.step2{border-color: #165CE8 !important;}
    .dialog-content{
        width: -webkit-fill-available;
        .dflt-dshbd-selection{
            color: #142330;
            font-family: Open Sans,sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
            letter-spacing: -0.32px;
            span{
                color: var(--Error-color-code, #E2494F);
                font-family: Open Sans,sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: -0.32px;
            }
        }
        .select-xlarge .MuiSelect-select{
            display: flex;
            padding: 7px 12px;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;
            background: #FFF !important;
            border-radius: 4px;
            border: 1px solid var(--neutral-08, #D6DADF);
            color: var(--neutral-04, #758496);
            font-family: Open Sans,sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
        }
    }
    @media screen and (max-width: 600px) {
        min-width: auto;
    }
    
}
.step2 .MuiDialog-container .MuiPaper-root{border-color: #165CE8; }
.Mui-disabled .QontoStepIcon-circle{
    color:#FFF;
    border:1px solid #DBE6F3;
  }