@use "/src/utils/colors";
@use "../../styles/Variables-Functions.scss" as *;

#report-catalog-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  button{
    text-transform: none !important;
  }

  .breadcrumb-list{
    background-color: #ffffff;
    .grid-item{
      display:inline;
      line-height:1.5;
      letter-spacing: -0.24px;
      font-size: 14px;
      color: #425059;
      font-weight: 600;
      font-family: 'Open Sans', sans-serif;
    }
    .home-grid{
      text-decoration:underline;
      padding-left:15px;
      color: #556674;
      font-weight: 300;
      cursor: pointer;
    }
    .action-items{
      min-height: auto;
      line-height:2;
      text-align:right;
    }
  }

  @media (max-width: 1080px) {
    margin-left: 0px;
  }

  .tab-panel{
    height: inherit;
    background: #F1F4F7;
  }

  .MuiBox-root{
      padding: 0 !important;
      height:100%
  }
  .tab-panel:first-child{
    height: inherit;
      padding: 0 !important;
  }
  .MuiPaper-root{
    background-color: #777777;
    color: white;
  }
  #tile-switch-container {
    display: flex;
    font-weight: 500;
    letter-spacing: 0.22px;
    margin-top: 20px;
    margin-left: 10px;
    * {
      margin-right: 10px;
    }
  }
  a{
    text-decoration: none;
  }
  
  .control-wrapper {
    z-index: 1;
    display: flex;
    width: 100%;
    position: relative;

    #type-outer-container {
      width: 50%;
    }

    .add-new-container {
      padding-left: 13px;
      width: 50%;
      margin-top: 20px;
      margin-right: 15px;
      right: 0;

      a {
        text-decoration: none;
      }

      div.add-btn {
        border: 1px solid colors.$blue5;
        height: 35px;
        border-radius: 20px;
        display: flex;
        width: fit-content;
        padding: 0 10px;
        transition: 150ms ease-in-out;
        font-weight: 400;
        align-items: center;
        padding-right: 13px;
        background: colors.$blue5;
        position: absolute;
        right: 0;

        span,
        svg {
          color: white;
        }

        svg {
          color: white;
          font-size: 20px;
        }

        &:hover {
          cursor: pointer;
          opacity: 0.9;
          transform: scale(1.1);
        }

        &:active {
          cursor: pointer;
        }
      }
    }

    .pin-favorite {
      margin-bottom: 0px;
      margin-top: 5px;
      float: right;
      .option {
        font-family: Rubik;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.6px;
        text-align: left;
        color: colors.$blue5;
      }

      .MuiCheckbox-colorPrimary.Mui-checked {
        color: colors.$blue5;
      }
    }
  }
  .control-wrapper::after {
    content: "";
    clear: both;
  }
  #report-tile-list {
    $max-tile-width: 140px;
    display: grid;
    font-family: Open Sans,sans-serif;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.6px;
    gap: 25px;
    grid-template-columns: repeat(auto-fit, $max-tile-width);
    place-items: start;

    > .report-tile {
      position: relative;
      background-color: white;
      box-sizing: border-box;
      height: calc(100% - 4px);
      width: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;
      box-shadow: 0 0px 8px 1px rgb(0 0 0 / 14%);
      transition: transform 150ms ease-in-out;
      &:hover {
        cursor: pointer;
        transform: scale(1.05);
      }

      // makes the aspect ratio square
      &::before {
        content: "";
        display: inline-block;
        width: 1px;
        height: 0;
        padding-bottom: calc(100% / 1);
      }

      .actions {
        position: absolute;
        top: 0;
        right: 5px;

        .MuiButton-root {
          padding: 2px 10px;
          svg {
            color: colors.$blue5;
          }
        }
      }

      .inner {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        .tile-icon {
          width: 40px;
          height: 40px;
          flex-shrink: 0;
          border-radius: 20px;
          align-self: center;
          margin-top: auto;
          margin-bottom: 10px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            padding: 6px;
            font-size: 0.6em;
          }
        }

        .tile-title {
          margin-top: 10px;
          font-weight: 500;
          font-size: 0.9em;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          max-height: 35px;
        }

        .tile-type {
          color: colors.$grey3;
          font-size: 0.9em;
        }
      }

      #tile-actions-button {
        min-width: 10px !important;
        background-color: transparent !important;
      }
    }
  }

  #report-header-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    > div {
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.26px;
    }

    div.sortable {
      width: fit-content;
      cursor: pointer;
      display: flex;
      justify-content: center;
      svg {
        font-size: 18px;
        color: #a19090;
        margin: 0px;
        margin-left: 5px;
      }

      svg.selected {
        color: colors.$blue5;
      }

      svg.reverse {
        transform: scale(1, -1);
      }
    }
  }
  .view-report{
    width: 100%;
    height: 100%;
    .back-btn{
      position: absolute;
      right: 15px;
      z-index: 1;
      color:#FFF;
      background: var(--main-primary-03, #2C4259);
      svg{
        color:#FFF;
      }
    }
  }
  .report-list-container{
    .search-container{
      display: inline-block;
    }
    .view-container{
      float: right;
      text-align: right;
      display: inline-block;
      .home-page-header-button{
        display: inline-block;
        padding:0 5px;
        .add-btn{
          height: 40px;
          vertical-align: middle;
          padding-top: 4px;
        }
      }
      
      @media (max-width: 500px) {
        float: none;
        padding-top: 10px;
      }
      button{
        width: auto;
      }
    }
    .table-container{
      margin:12px;
      .tbl-row{
        margin: 0 0 10px 0;
        padding: 15px;
        background: #fff;
        border-radius: 4px;
        h6{
          margin: 0;
          color: #142330;
          font-family: Open Sans,sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
        }
        small{
          color: #556674;
          font-family: Open Sans,sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
        }
        .action-block{
          text-align: right;
          .action-container{
            display: inline-block;
            text-align: center;
            cursor: not-allowed;
            border: none;
            background: none;
            vertical-align: top;
            padding-block: 0;
            padding-inline: 0;
            svg{
              width: 20px;
              height: 20px;
              vertical-align: super;
            }
            p{
              color: #142330;
              font-family: Open Sans,sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px;
              letter-spacing: -0.28px;
              display: block;
              @media (max-width: 1080px) {
                  display: none;
              }
             
            }
          }
          .action-container.run-report{
            cursor: pointer;
          }
          .vr{
            border-left: 1px solid rgba(117, 132, 150, 0.31);
            height: 100%;
            display: inline-block;
            margin-left: 15px;
            margin-right: 15px;
            @media (max-width: 1080px) {
             
                height: 40%;
            }
            @media (max-width: 680px) {
             
              height: 25%;
          }
            @media (max-width: 470px) {
             
              height: 15%;
          }
          }
         
        }
        
      }
    }
    }

  .favorite-column {
    width: 2%;
    padding-left: 1% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;

    div {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        padding-top: 0px !important;
      }
    }

    .progress {
      display: table;
      align-items: center;
      justify-content: center;
      svg {
        color: colors.$blue1;
        padding: 0 !important;
      }
    }
  }
  .first-column {
    width: 45%;
    padding-left: 1% !important;
    line-height: 1;
    min-width: 200px;
  }
  .second-column {
    width: 200px !important;
    padding-left: 1% !important;
    line-height: 1;
    min-width: 200px;
    text-align: center;
  }
  .third-column {
    width: 200px !important;
    padding-left: 1% !important;
    line-height: 1;
    min-width: 200px;
    text-align: center;
  }
  .fourth-column {
    width: 160px;
    text-align: center;
    a {
      color: #00aaa9;
      text-decoration: underline !important;
    }

    a:hover {
      opacity: 0.8;
    }
  }

  .actions-column {
    width: 100px;
    text-align: center;
    div {
      display: flex;
      text-align: center;
      justify-content: center;

      svg {
        padding-right: 5px;
        height: 70%;
      }
    }
  }

  .tabs-list{
    min-height: 28px;
    button{
      
    &.Mui-selected{
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: -0.02em;
        color: colors.$blue1;
    }
    font-family: "Open Sans", sans-serif;
    font-style: normal;
      line-height: 20px;
      color: #637583;
      min-height: auto;
      padding: 0 16px;
    }
    .MuiTabs-indicator{
      background: #AF1E37;
      border-radius: 2px 2px 0px 0px;
      height: 4px;
    }
  }
}

.unfavorite-container {
  display: inline-flex;
  width: 140px;
  justify-content: center;
}

.popper-container {
  font-size: small;
  background-color: white;
  padding: 2px;
  border: 1px solid colors.$blue3;
}
.home-page-header-button {
  a {
      text-decoration: none;
  }

  .add-btn {
      border: 1px solid $blueColor;
      height: 35px;
      display: flex;
      width: 100%;
      justify-content: center;
      transition: 150ms ease-in-out;
      font-weight: 600;
      font-size: 16px;
      line-height: 14px;
      letter-spacing: -0.02em;
      color: #FFFFFF;
      align-items: center;
      background-color: $blueColor;
      right: 0;
      box-sizing: border-box;
      border-radius: 4px;
      min-width: 128px;

      span,
      svg {
          color: #FFFFFF;
      }

      svg {
          color: #FFFFFF;
          font-size: 20px;
      }

      &:hover {
          cursor: pointer;
          opacity: 0.9;
          transform: scale(1.1);
      }

      &:active {
          cursor: pointer;
      }
  }
}
@media screen and (max-width: 600px) {
  #report-catalog-main{
    padding-top: 10px;
    #report-list-container {
      padding: 0px; 
      margin-top: 0px; 
      }
}
#report-catalog-main .add-btn{
  margin-top: 0;
}.add-btn{
  margin-top: 0;
  margin-right: 15px;
}
.tab-panel .MuiBox-root{
  padding: 0;
  padding-top: 5px;
}
}
.add-dashboard{
  text-align: right;
  margin:5px;
}
.add-dashboard a{
  border: 1px solid colors.$blue5;
  background: colors.$blue5;
  color: white;
  border-radius: 20px;
  padding: 5px;
  transition: 150ms ease-in-out;
  &:hover{
    cursor: pointer;
      opacity: .9;
      transform: scale(1.1);
      background: colors.$blue5 !important;
  }
}
.add-dashboard a svg{
  font-size: 21px;
  vertical-align: middle;
}
.add-dashboard a span{
  font-weight: 400;
  vertical-align: middle
}
.noreports{
  text-align: center;
  svg{
  height: auto;
    padding-top: 30px;
    width: 25%;
  }
}
.no-rpt-txt{
  padding: 0% 15%;
 
  p{
  color: colors.$blue1;
  text-align: center;
  font-family: Open Sans, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: -0.24px;
  padding-bottom: 10px;
  }
  @media screen and  (min-width:1200px){
    padding: 0 23%;
    p{
      font-size: 1.4rem;
      line-height: 39.5px;
    }
  }
}
.disabled{
  cursor: not-allowed;
  pointer-events: none;
}

.report-tile-list{
  .report-tile{
    background: #fff;
    margin: 15px 6px 0 12px;
    padding: 16px;
    display: inline-block;
    p.title{
      color: #142330;
      font-family: Open Sans,sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
    small{
      color: #556674;
      font-family: Open Sans,sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
    }
    .action-list{
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
    .action-container{
      display: flex;
      align-items: center;
      gap: 8px;
      .vr{
        border-left: 1px solid rgba(117, 132, 150, 0.31);
        display: inline-block;
      }
    }
  }
}