@use "/src/utils/colors";

#report-form-page {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  transform: translateY(-10%);

  #report-form-main {
    background: white;
    border-radius: 10px;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px;
    max-width: 900px;
    min-width: 600px;
    margin-left: 1.5%;

    @media (max-width: 1080px) {
      margin-left: 0;
    }

    .title {
      color: colors.$blue4;
      letter-spacing: 0.32px;
      line-height: 35px;
      width: 100%;
      font-weight: 500;
      padding-top: 20px;
      padding-bottom: 10px;
      margin-bottom: 20px;
    }

    .form-container {
      padding-top: 20px;
      padding-left: 0px;
    }

    .form-row {
      width: 100%;
      clear: both;
      padding-bottom: 50px;
      display: flex;
    }

    .desc-row {
      width: 100%;
      clear: both;
      padding-bottom: 50px;
      height: 50px;
    }

    .w-50 {
      width: 50%;
    }

    .w-40 {
      width: 40%;
    }

    .w-25 {
      width: 25%;
    }

    .pr-25 {
      padding-right: 25px;
    }

    .label-cell {
      color: colors.$blue5;
      letter-spacing: 0.28px;
      width: 100%;
    }

    .control-cell {
      position: relative;
      width: 100%;
      padding-top: 10px;
      padding-left: 0;
      height: 60px;

      .loading {
        position: absolute;
        left: -28px;
        top: 24px;
      }
    }

    input {
      border: none;
      height: 28px;
      outline: none;
      color: colors.$blue5;
      margin-right: 5px;
      width: 95%;
      padding-left: 10px;
      font-family: Rubik;
      border-radius: 5px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
      background-color: rgba(85, 136, 163, 0.2);
      height: 40px;
      &:focus {
        border: 2px solid colors.$blue4;
      }
    }

    input:disabled {
      background-color: #e8e8e8;
    }

    .input-error {
      border: 1px solid #a04b5a;
    }

    .select {
      font-family: Rubik;
      letter-spacing: 0.28px;
      text-align: left;
      color: colors.$blue5;
      height: 35px;
      padding-top: 5px;
      width: 92%;
      border-radius: 5px;

      svg {
        position: absolute;
        right: 10px;
        top: 12px;
        font-size: 20px;
        color: colors.$blue5;
      }
    }

    .Mui-disabled {
      -webkit-text-fill-color: colors.$blue5;
    }

    textarea,
    textarea:focus {
      border: none !important;
      height: 70px;
      outline: none;
      color: #145374;
      margin-right: 5px;
      padding-left: 10px;
      font-family: Rubik;
      resize: none;
      border-radius: 5px;
      box-shadow: 0 10px 30px 0 rgb(0 0 0 / 10%);
      background-color: rgba(85, 136, 163, 0.2);
      width: 100%;
      padding-top: 10px;
    }

    .error {
      font-family: Rubik;
      color: #a04b5a;
      padding-top: 8px;
      padding-left: 5px;
      display: block;
    }

    .info {
      font-family: Rubik;
      font-style: italic;
      color: colors.$grey3;
      padding-top: 8px;
      padding-left: 5px;
      display: block;
    }

    .btn-row {
      width: 100%;
      padding-bottom: 40px;
      margin-top: 40px;
      clear: both;
    }

    .save-button {
      font-family: Rubik;
      border: 1px solid colors.$blue2;
      background-color: colors.$blue2;
      height: 40px;
      width: 148px;
      border-radius: 100px;
      box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.3);
      transition: 150ms ease-in-out;
      text-transform: uppercase;
      color: #e8e8e8;
      font-weight: 400;
      cursor: pointer;
      letter-spacing: 1.4px;
      line-height: 2.5;
      &:focus {
        border: 2px solid colors.$blue4;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          padding-right: 5px;
        }
      }

      &:hover {
        border: 1px solid colors.$blue1;
        background-color: colors.$blue1;
      }

      &:disabled {
        border: 1px solid colors.$grey5;
        background-color: colors.$grey5;
        cursor: not-allowed;
      }
    }

    .cancel-button {
      background: transparent;
      font-family: Rubik;
      font-weight: 400;
      line-height: 2.5;
      letter-spacing: 1.68px;
      text-align: left;
      color: colors.$grey5;
      text-decoration: none;
      margin-left: 40px;
      text-transform: uppercase;
      &:focus {
        border: 2px solid colors.$blue4;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.rf-menu-item-inner {
  display: flex;
  flex-flow: row wrap;
  max-width: 400px;
  min-width: 120px;
  width: 100%;
  div:first-of-type {
    overflow: hidden;
    width: calc(100% - 1.5em);
    text-overflow: ellipsis;
  }
  div.subtitle {
    font-size: 0.8em;
    font-style: italic;
    color: colors.$grey3;
  }
  svg {
    margin-left: auto;
  }
}

// when selected
.MuiSelect-select {
  .rf-menu-item-inner {
    svg {
      display: none;
    }
  }
}

ul.rcf-reports-same-list {
  max-height: 300px;
  overflow-y: auto;
}

#rc-colorpicker-container {
  display: flex;
  justify-content: center;
  width: 100%;
  .react-colorful {
    width: 100%;
    height: 100px;
  }
}
