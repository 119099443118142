@use "/src/utils/colors";

.dialog-content {
    min-height: 200px;
    height: 100%;
    padding: 15px;
    font-family: 'Open Sans', sans-serif;
}
.copy-link{
    margin-right: 10px !important;
}
.report-share .MuiPaper-root{
  position: absolute;
  border-radius: 0;
  margin: 0;
  top: 0;
  bottom: 0;
  max-height: 100%;
  height: 100%;
  right: -34%;
  transition: opacity 0.3s linear, right 0.3s ease-out !important;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out !important;

}
.report-share .MuiPaper-root .shared-with{
    width: 90%;
}

.report-share input{
    width: 100%;
    padding: 10px;
    padding: 3px;
    font-size: 0.999rem;
}
.share-title{
    font-size: 15px !important;
    line-height: 2.5 !important;
}
.report-share .dialog-footer .email-action {
    letter-spacing: 1.2px;
    text-align: left;
    color: #5588a3;
    border-radius: 100px !important;
    border: solid 2px #5588a3 !important;
    padding: 5px 15px 5px 15px;
    font-weight: 400 !important;
    min-width: 110px;
    min-height: 30px;
}
// .dialog-footer .email-action:hover {
//   color:#fff
// }
.input-container {
    height: 40px;
    font-weight: 400;
    background: rgba(85, 136, 163, 0.2);
    border-radius: 5px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 9;

    &.email-message-container {
      min-height: 100px;
      height: unset;
    }
  }
.copy-link-succes{
  color: green;
    float: right;
    text-align: center;
    width: 100%;
}

.report-share button {
    height: 36px;
    padding: 6px 10px 5px 10px;
    border-radius: 18px;
    border: 2px solid colors.$blue4;
    background-color: white;
    color: colors.$blue4;
    letter-spacing: 2.5px;
    font-weight: 500 !important;
    text-transform: none;
    margin-top: 2px;
    // min-width: 100px;
    transition: transform 150ms ease-in-out;

    &.close {
      border: none;
      color: colors.$grey3;
      margin-right: 20px;
      &:hover {
        background-color: colors.$grey2;
      }
    }

    &:hover:not(.close) {
      transform: scale(1.05);
      background-color: colors.$blue4;
      color: white !important;
      cursor: pointer;
    }

    &:disabled {
      cursor: not-allowed !important;
      color: colors.$grey3;
      border-color: colors.$grey3;
    }
  }

  .MuiAccordion-root {
    box-shadow: none !important;
    width: inherit;

    &::before {
      display: none;
    }

    .MuiAccordionSummary-root {
      padding: 0 !important;
      .MuiAccordionSummary-content {
        margin: 0 !important;
        #expand-collapse-button {
          background-color: colors.$blue4;
          color: white;
          border-radius: 18px;
          height: 36px;
          width:90%;
          text-align: center;
        }
      }
    }

    .MuiAccordionDetails-root {
      padding: 10px !important;

      .shared-with {
        max-height: 180px !important;
        overflow: auto !important;
        scrollbar-color: colors.$blue4 rgba(149, 148, 148, 0.3);
        scrollbar-width: thin;

        > div {
          display: flex;
          height: 50px;
          align-items: center;
          color: colors.$blue5;

          &:not(:first-of-type) {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
          }

          div:first-child {
            width: 90%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          div:last-child {
            width: 120px;
            text-align: end;

            button {
              height: 30px;
              padding: 6px 11px 5px 12px;
              border-radius: 10px;
              border: none;
              background-color: colors.$blue4;
              color: #fff;
              text-transform: none;
              letter-spacing: unset;
              width: 80px;

              svg {
                margin-right: 3px;
              }
            }

            button:hover {
              transform: unset;
              background-color: colors.$blue5;
            }

            button:disabled {
              cursor: not-allowed !important;
              color: #fff !important;
              background-color: #959494 !important;
            }
          }
        }
      }
    }
  }

  
   .in .MuiPaper-root {
    right: 0;
   
  }

.report-share{
  .dialog-title{
    color: colors.$blue1;
    font-size: 15px;
    font-weight: 700 !important;
    line-height: 20px;
    letter-spacing: -0.36px;
    border-bottom: 1px solid rgba(117, 132, 150, 0.18);
    padding: 15px 5px 10px 15px;
    text-transform: none;

  }
  .close{
    margin-left: -34px;
    cursor: pointer;
    position: fixed;
    display: flex;
    padding: 5.7px;
    justify-content: center;
    align-items: center;
    border-radius: 4px 0px 0px 4px;
    flex-shrink: 0;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.13);
  }
  .dialog-content {
    button{
      letter-spacing: normal;
    }
    .MuiPaper-root{
    position: relative;
    }
  }
}
@media screen and (max-width: 600px) {
  .in .MuiPaper-root {
    left: 0;
   
  }
  .dialog-footer {
    padding: 5px;
  }
}