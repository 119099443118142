@use "/src/utils/colors";
#subscription-container, #filestorage-container,#contactus-container{
    .sub-header{
        background: #FFF;
        padding: 10px 10px 10px 12px;
        p{
            color: colors.$blue1;
            font-family: Open Sans,sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: -0.2px;
            display: inline-block;
            padding-top: 5px;
        }
        .cat-list{
            .MuiInputBase-root{
                width: 100%;
                &:hover{
                    outline: 0;
                }
                .MuiSelect-select{
                    padding: 10px;
                    em{
                        font-style: normal;
                    }
                }
                .Mui-focused .MuiOutlinedInput-notchedOutlined{
                    border: none;
                }
            }
        }
        .heading-list em{
            font-style: normal;
        }
    }
    .filter-section{
        background: #fff;
        margin-top: 2px;
        padding:10px 10px 10px 12px;
    .search-container{
        
        label{
            padding-left: 20px;
            color: #637583;
            font-family: Open Sans,sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: -0.32px;
        }
        .MuiFormControl-root{
                width: 100%;
        border-radius: 4px;
        margin: 0;

        background: rgb(242 244 247);
        border-color: rgb(242 244 247);
        .MuiOutlinedInput-root{
            padding-left: 0;
            padding-right: 0;
            fieldset {
                border-color: rgb(242 244 247);
            }
            svg{
                fill: #8E99A7;
            }
        }
        }
        
        .MuiOutlinedInput-input{
            padding-left: 15px;
            color: colors.$blue1;

        }
        .filter-date{
            text-align: right;
            padding: 5px 0px 5px 0px;
            p{
                color: var(--neutral-01, #58687C);
                font-family: Open Sans, sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                display: inline-block;
                vertical-align: super;
                &.last-report, &.next-report{
                    border-radius: 4px;
                    border: 1px solid var(--neutral-08, #D6DADF);
                    background: #FFF;
                    padding: 10px 10px;
                    margin-left: 8px;
                    cursor: pointer;
                    color: #142330;
                    font-family: Open Sans, sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 14px; 
                    letter-spacing: -0.28px;
                }
                svg{
                    padding-right: 5px;
                    vertical-align: middle;
                }
            }
            
        }
        .last-report, .next-report{
            display: inline-block;
        vertical-align: middle;
        }
        // @media (max-width: 900px) {
        //     padding-left: 16px;
        // }
    }
    .MuiGrid-item{
        padding-top: 0;
        // padding-left: 20px;
    }
    .lastRunDateUTC{
        .MuiGrid-item{
            text-align: right;
            @media (max-width: 600px) {
                text-align: left;
        }
        @media (min-width: 1200px) {
            text-align: center;
    }
        }
    }
    .nextRunDateUTC{

            @media (max-width: 900px) and (min-width:600px) {
                padding-top: 10px !important;
        }
    }
    }
    table{
        width: 100%;
        thead th{
            line-height: 1.5;
            padding: 15px 8px !important;
        }
    }

    th.sortable span{
        color: var(--neutral-01, #58687C);
        font-family: Open Sans, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
    }
    .table-header{
        // display: block;
        padding: 10px 16px;
        background: #FFF;
        width: 100%;
        border-bottom: 2px solid var(--csk-142330100, #DBE6F0);
    }
    .MuiTableContainer-root{
        max-height: inherit;
        width:98.3%;
        @media (max-width: 900px){
            margin: 6px 0 0 0 !important;
            width:99.8%
    }
        table {
            thead tr {
            border-bottom: 2px solid var(--csk-142330100, #DBE6F0);
            }
            tbody tr {
                border-bottom: 1px solid var(--csk-142330100, #DBE6F0);
                td:last-child .action-items{
                    background: none;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding: 6px;
                    display: flex;
                    svg{
                        width: 20px;
                        padding-right: 5px;
                        cursor: pointer;
                    }
                    .active-container,.toggle-switch{
                        padding-top: 5px;
                        padding-right: 5px;
                    }
                }
            }
        }
    }
    .row-background{
        background: rgba(241, 244, 247, 0.50);
    }
    .MuiTableRow-root:nth-of-type(4n-1) {
        background: rgba(241, 244, 247, 0.50);
      }
    .MuiTableCell-root {
        padding: 4px;
        a {
            color: var(--common-blue-link, #165CE8);
            font-family: Open Sans, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            text-decoration: none;
        }
       
    }
    .MuiTableCell-root button:disabled {
        svg path{
            fill: #9CB0C8;
        }
    }
    .collapsible-block{
        padding: 15px;
        background: rgba(241, 244, 247, 0.50);
        border-left: 3px solid #AF1E37;
        display: none;
        label,.title{
            color: #000;
        font-family: Open Sans, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 175% */
        }
        p{
            color: var(--neutral-01, #58687C);
            font-family: Open Sans,sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; /* 162.5% */
            display: inline-block;
        }
        &.in{
            display: table-cell !important;
        }
    }
    .no-subscriptions, .spinner{
        text-align: center;
        padding: 25px 15px 15px;
    }
    .footer{
        .pagination {
            padding-left:5px;
              ul{
              float: right;
              padding: 6px;
              width: 98%;
              background: #FFF;
              border: none;
              position: relative;
              button{
                  border-color:#DBE6F0;
              }
              svg{
                  fill:#165CE8
              }
              
              }
          }
          .page-status{
              color: #000;
              font-family: Open Sans,sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 1;
              display: inline-block;
              padding-top: 15px;
              padding-left: 15px;
              span{
              color: var(--csk-142330100, #DBE6F0);
              }
          }
    }
    .tblrow-title{
        color: colors.$blue1 !important;
        text-align: left;
        min-width: 240px;
        width: 50%;
        font-family: Open Sans, sans-serif !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 1.5 !important; 
        padding-left: 10px;
    }
    .filters-footer{
        padding: 8px 0 0 15px;
        .title{
            color: #2C4259;
            font-family: Open Sans,sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 125% */
            letter-spacing: -0.32px;
        }
        p{
            display: inline-block;
            margin-right: 10px;
        }
        .filter{
            // height: 32px;
            padding: 8px 12px;
            align-items: center;
            gap: 8px;
            border-radius: 4px;
            border: 1px solid rgba(142, 153, 167, 0.18);
            background: var(--white, #FFF);
            color: #142330;
            font-family: Open Sans,sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.32px;
        }
        .clear-all{
            color: #4664F0;
            font-family: Open Sans,sans-serif;
            font-style: normal;
            font-size: 14px;
            font-weight: 700;
            line-height: 14px; /* 100% */
            letter-spacing: -0.28px;
            svg{
                vertical-align: middle;
            }
        }
    }
    .legend{
        line-height: 2.5;
        text-align: left;
        @media (min-width: 1200px) {
            line-height: normal;
            text-align: right !important;
        }
        .MuiCheckbox-root{
            padding: 0;
            svg path{
                fill:#EAECEE;
            }
            &.Mui-checked{
                svg path{
                    fill:#165CE8;
                }
            }
        }
        p{
            color: var(--neutral-01, #58687C);
            font-family: Open Sans,sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            display: inline-block;
        }
         .status{
            text-align: left;
            display: inline-block;
            margin-left: 5px;
            vertical-align: middle;
            line-height: 2.2;
         }
    }
    .legend,.status{
        text-align: left;
        span.status-name{
            padding: 4px 8px;
            border-radius: 4px;
            border: 1px solid;
            border-color:#99D39F;
            background: #FBFDFF;
            color: #33933D;
            font-family: Open Sans, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: normal;
            svg{
                vertical-align: middle;
                padding-right: 4px;
            }
        }
        span.status-message{
            color: var(--main-primary-01, #142330);
            font-family: Open Sans,sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            padding-left: 5px;
        }
    }
    .legend .success,.status .success{
        .status-name{
            color:#33933D;
            border-color:#99D39F;
            svg circle{
                fill:"#33933D"
            }
        }
        
    } 
    .legend .queue,.status .queue {
        .status-name{
            color:#F8C45A;
            border-color:#E9CC91;
            svg circle{
                fill:#F8C45A
            }
        }
        
    }
    .legend .error-block,.status .error-block {
       .status-name{
        color:#BA3D42;
        border-color:#FFB1B4;
        svg circle{
            fill:#BA3D42
            
        }
       }
    }
    .legend .in-progress,.status .in-progress {
        .status-name{
            border-color:#90A1F6;
            color:#5469CF;
            svg circle{
                fill:#5469CF
            }
        }
    }
}

#filestorage-container{
    .label-container{
        @media (min-width: 600px) and (max-width: 900px) {
            text-align: center;
        }
    }
}