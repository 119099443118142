.paystub-container p,h4{
        padding: 2px;
        margin:0
}
.ags-block,.emp-block{
    display: inline-block;
    width: 50%;
    vertical-align: top;
    font-size: 12px;
}
.inlineBlock{
    display: inline-block;
    vertical-align: top;
}
.table-section{
    border-bottom:2px solid;
    width:100%;
    border-top:2px solid;
}
.table-data{
    border-right:2px solid;vertical-align:top;width:35%;
}
.w-30{
    width:32%
}
.table-block{
    width: 100%;
    /* border-right: 2px solid black;
    border-top: 2px solid black;
    border-bottom: 2px solid black; */
    
}
.common-earnings{
    padding-top:30px;
}
table{
    /* border: 2px solid black;; */
}
th{
    padding: 0px;
    text-align: left;
}
/* .final-amount{
    width: 100vh;
} */
.final-amount> table, .final-amount>table th, .final-amount>table tr td {
    width:100%;
    padding:5px;
    border: 1px solid black;
    border-collapse: collapse;
  }
.void-block{
    padding: 20px 20px;
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
    margin: 1rem;
}
.emp-add{
    padding-left: 15px;
    width: 80%;
}
.pay-block{
    display: inline-block;
    width: 65%;
    vertical-align: top;
}
.non-nego{
    width: 35%;
    vertical-align: top;
    display: inline-block;
}
h5{
    margin: 0;
    padding: 0;
}
h3{
    margin:0
}
.m-20{
    margin:20px 0;
}

.sign-block{
    margin-top: 4rem;
}
.txt-right{
    float: right;
}
p{
    margin: 0;
}