@use "/src/utils/colors";

#settings-form {
  background: white;
  border-radius: 10px;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px;
  max-width: 900px;
  min-width: 600px;
  margin-left: 1.5%;

  @media (max-width: 1080px) {
    margin-left: 0;
  }

  .title {
    color: colors.$blue4;
    letter-spacing: 0.32px;
    line-height: 35px;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid colors.$blue4;
  }

  .sub-title {
    color: colors.$blue5;
    letter-spacing: 0.26px;
    line-height: 35px;
    width: 100%;
    font-weight: 500;
    padding-top: 30px;
    padding-left: 30px;
    clear: both;
  }

  .form-container {
    padding-top: 20px;
    padding-left: 40px;

    .progress-bar-container {
      width: 100%;

      .progress-bar {
        margin-left: 20px;

        .progress-bar-text {
          padding-bottom: 5px;
          display: flex;
          .left {
            width: 60%;
          }

          .right {
            width: 40%;
            text-align: right;
          }
          .warning {
            color: #e89020;
          }
          .error {
            color: #a04b5a;
          }
        }
      }
    }
  }

  .form-cell {
    display: flex;
    color: colors.$blue5;
    letter-spacing: 0.28px;
    align-items: center;

    div:first-child {
      margin-right: 10px;
    }
  }

  .form-cell-disabled {
    color: colors.$grey5;
    letter-spacing: 0.28px;
    float: left;
  }

  .cell-sep {
    width: 50px;
    float: left;
  }

  input,
  input:focus {
    margin-left: 15px;
    border-radius: 3px;
    border: 1px solid #959494 !important;
    height: 22px;
    margin-top: -5px;
    outline: none;
    color: colors.$grey5;
    width: 50px;
    margin-right: 5px;
  }

  // .select-div {
  //   margin-left: 15px;
  //   margin-top: -7px;
  // }

  #timeout-value-select {
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: Rubik;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.28px;
    text-align: left;
    color: colors.$blue5;
  }

  .btn-row {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 40px;
    clear: both;
    margin-left: 30px;
  }

  button {
    font-family: Rubik;
    border: 1px solid colors.$blue2;
    background-color: colors.$blue2;
    height: 40px;
    width: 148px;
    border-radius: 100px;
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.3);
    transition: 150ms ease-in-out;
    text-transform: uppercase;
    color: #e8e8e8;
    cursor: pointer;
    letter-spacing: 1.4px;
    line-height: 2.5;

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        padding-right: 5px;
      }
    }
  }

  button:hover {
    border: 1px solid colors.$blue1;
    background-color: colors.$blue1;
  }

  button:disabled {
    border: 1px solid colors.$grey5;
    background-color: colors.$grey5;
    cursor: not-allowed;
  }
}

.storage-wrapper {
  margin-top: 0;

  @media (max-width: 1080px) {
    margin-top: 20px !important;
  }
}
