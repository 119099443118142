@use "/src/utils/colors";
.filter-section{
    background: #fff;
    margin-top: 2px;
    padding:10px 10px 10px 12px;
.search-container{
    
    label{
        padding-left: 20px;
        color: #637583;
        font-family: Open Sans,sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.32px;
    }
    .MuiFormControl-root{
            width: 100%;
    border-radius: 4px;
    margin: 0;

    background: rgb(242 244 247);
    border-color: rgb(242 244 247);
    .MuiOutlinedInput-root{
        padding-left: 0;
        padding-right: 0;
        fieldset {
            border-color: rgb(242 244 247);
        }
        svg{
            fill: #8E99A7;
        }
    }
    }
    
    .MuiOutlinedInput-input{
        padding-left: 15px;
        color: colors.$blue1;

    }
    .filter-date{
        text-align: right;
        padding: 5px 0px 5px 0px;
        p{
            color: var(--neutral-01, #58687C);
            font-family: Open Sans, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            display: inline-block;
            vertical-align: super;
            &.last-report, &.next-report{
                border-radius: 4px;
                border: 1px solid var(--neutral-08, #D6DADF);
                background: #FFF;
                padding: 10px 10px;
                margin-left: 8px;
                cursor: pointer;
                color: #142330;
                font-family: Open Sans, sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 14px; 
                letter-spacing: -0.28px;
            }
            svg{
                padding-right: 5px;
                vertical-align: middle;
            }
        }
        
    }
    .last-report, .next-report{
        display: inline-block;
    vertical-align: middle;
    }
}

}