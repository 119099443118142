.dialog-right{
  .dialog-title{
    color: var(--main-primary-01, #142330);
    font-size: 15px;
    font-weight: 700 !important;
    line-height: 20px;
    letter-spacing: -0.36px;
    border-bottom: 1px solid rgba(117, 132, 150, 0.18);
    padding: 15px 5px 10px 15px;
    text-transform: none;
    
  .del-subscription{
    float: right;
    cursor: pointer;
    path{
      fill:white;
      stroke: red;
    }
  }

  }
  .close{
    margin-left: -34px;
    cursor: pointer;
    position: fixed;
    display: flex;
    padding: 5.7px;
    justify-content: center;
    align-items: center;
    border-radius: 4px 0px 0px 4px;
    flex-shrink: 0;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.13);
  }
  .dialog-content {
    height: auto;
    min-height: auto;
    button{
      letter-spacing: normal;
    }
    .MuiPaper-root{
    position: relative;
    }
    .MuiToggleButtonGroup-root{
      width: 100%;
    }
  }
  .MuiPaper-root{
      position: absolute;
      border-radius: 0;
      margin: 0;
      top: 0;
      bottom: 0;
      max-height: 100%;
      height: 100%;
      right: -34%;
      transition: opacity 0.3s linear, right 0.3s ease-out !important;
      -webkit-transition: opacity 0.3s linear, right 0.3s ease-out !important;
      -moz-transition: opacity 0.3s linear, right 0.3s ease-out !important;
      -o-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  
  }
  }
  .in .MuiPaper-root {
      right: 0;
    }

  .MuiToggleButtonGroup-root button{
    display: flex;
    width: 215px;
    padding: 7px 12px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    color: var(--main-primary-01, #142330);
    text-align: center;
    font-size: 14px;
    font-family: Open Sans,sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    border: 1px solid var(--neutral-08, #D6DADF);
    text-transform: none;
    border-radius: 4px 0px 0px 4px;
    border-top: 1px solid var(--neutral-08, #D6DADF);
    border-bottom: 1px solid var(--neutral-08, #D6DADF);
    border-left: 1px solid var(--neutral-08, #D6DADF);
  }
  .MuiToggleButtonGroup-root button.selected{
    border: 1px solid var(--neutral-01, #58687C) !important;
    background: var(--neutral-10, #F1F4F7);
    margin-left: 0 !important;
    border-radius: 4px 4px 4px 4px;
  }
@media screen and (max-width: 600px) {
  .in .MuiPaper-root {
    left: 0;
   min-width: auto !important;
  }
  .dialog-footer {
    padding: 5px;
  }
}
.dialog-footer{
    padding: 0 15px 20px 30px;
    .btn-cancel{
    border-radius: 4px;
  border: 1px solid #2C4259;
  background: var(--white, #FFF);
  font-family: 'Open Sans', sans-serif;
  text-transform: none;
  color: var(--main-primary-01, #142330);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.32px;
  min-height: 40px;
  margin-right: 15px;
  }
  .btn-save{
    border-radius: 4px;
    border: 1px solid #2C4259;
    background: var(--main-primary-03, #2C4259);
    display: inline-flex;
    padding: 12px 16px;
    align-items: center;
    gap: 8px;
    color: #FFF;
    font-size: 16px;
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: -0.32px;
    text-transform: none;
    &:disabled{
      color: var(--neutral-06, #9CB0C8);
      border: 1px solid var(--csk-142330100, #DBE6F0);
      background: var(--csk-142330100, #DBE6F0);
      cursor:not-allowed;

    }
    &:hover{
      background: var(--main-primary-03, #2C4259);
      
    }
    svg{
      vertical-align: middle;
    }
  }
  }
  .recurrence-form{
    .MuiToggleButtonGroup-root button{
      width: -webkit-fill-available !important;
  }
  .frequency-right{
    padding-top: 0.8em;
  }
  .input-maiden{
    font-family: Open Sans,sans-serif;
    right: 3em;
    position: absolute;
    padding: 10px 12px;
    font-size: 15px;
  }
  .indicator-maiden{
    right: 10px;
    position: absolute;
    padding: 8px 12px;
    transform: rotate(90deg);
    cursor: pointer;
  }
  .small-error{
    font-size: 12px;
    padding: 5px;
    color: red;
  }
  }