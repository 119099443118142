@use "/src/utils/colors";
#notification-container {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding: 13px 18px 12px 20px;
  z-index: 2000;
  opacity: 0.98;
  display: flex;
  color: #e8e8e8;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.28px;
  transition: transform 500ms ease-in-out;
  border-radius: 4px;
  border: 1px solid;
  box-shadow: 0px 9px 5px 0px rgba(22, 22, 22, 0.10);
  &.hidden {
    transform: translateY(-101%);
    box-shadow: none;
  }

  > span {
    flex-grow: 1;
    text-align: center;
    color: colors.$blue1;
    font-size: 14px;
    font-family: Open Sans, sans-serif;
    font-weight: 600;
    line-height: 1.5;
    vertical-align: middle;
    letter-spacing: -0.36px;

    svg {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .message.info, .message.loading{
    border-right: 1px solid rgba(255, 255, 255, 0.28);
  }
  .message.warning{
    border-right:1px solid rgba(91, 106, 240, 0.19)
  }
  .message.error{
    border-right:1px solid rgba(175, 30, 55, 0.10)
  }
  .message.success{
    border-right:1px solid rgba(20, 158, 88, 0.10)
  }
  .info,.loading {
    color: #fff;
    svg{
      vertical-align: middle;
      path{
        stroke: #fff;
      }
    }
    
  }
  p svg{
    padding-left: 5px;
    vertical-align: middle
  }

  .warning #Vector-Circle{
    fill:#F8C45A;
  }

  svg:last-child {
    cursor: pointer;
    height: 19px;
  }

  .n-progress {
    color: white !important;
    padding: 0;
  }
  svg.error, p.error, span.error{
    padding-top: 0;
  }
  @media (max-width: 600px){
    width: 80%;
  }
}
