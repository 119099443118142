.MuiStepper-root{
    width: 100%;
    .Mui-active{
        font-weight: 700 !important;
        line-height: 24px !important; /* 171.429% */
    }
    .MuiStepLabel-label{
        color: var(--main-primary-01, #142330);

        /* Para14px/Regular */
        font-family: Open Sans,sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 185.714% */
    
}
    .MuiStepLabel-label.MuiStepLabel-alternativeLabel{
        margin-top: 0;
    }
    .MuiStepConnector-root {
        left: calc(-50% + 4px) !important;
        right: calc(50% + 4px) !important;
    }
}
.MuiDialog-container .MuiPaper-root {
    min-width: 430px;
    &.step2{border-color: #165CE8 !important;}
}
.step2 .MuiDialog-container .MuiPaper-root{border-color: #165CE8; }
.Mui-disabled .QontoStepIcon-circle{
    color:#FFF;
    border:1px solid #DBE6F3;
  }