// This file holds all of the colors used in the app
// To reference, first import in your css by: @use '/src/utils/colors';
// Then reference a color by: colors.$blue2

// $blue1: #00334e
$blue1: #142330;
$blue2: #60819a;
$blue3: #c4d4e0;
$blue4: #5588a3;
$blue5: #145374;

$grey1: #e9e9e9;
$grey2: rgba(148, 148, 148, 0.2);
$grey3: #7e7e7e;
$grey4: #303030;
$grey5: #959494;

$red1: #a04b5a;

$lightblue: #6dabcc;
