@use "/src/utils/colors";

#filters-container {
  align-self: flex-start;
  letter-spacing: 0.26px;
  width: 100%;
  border-radius: 10px;
  transition: height 200ms ease;

  .filters-header {
    display: flex;
    color: colors.$blue1;
    border-radius: 10px 10px 0 0;
    padding-left: 5px;
    align-items: center;
    border-bottom: 2px solid colors.$blue1;

    svg {
      margin-left: 5px;
      margin-right: 5px;
      font-size: 19px;
    }

    span {
      svg {
        margin-top: 5px;
        margin-left: 15px;
        font-size: 22px;
        cursor: pointer;
      }
    }
  }

  .filters-body {
    color: colors.$blue1;
    border-radius: 0 0 10px 10px;
    display: flex;

    .filters {
      width: 100%;

      .filter-wrapper {
        font-weight: 500;

        .date-filter-container {
          display: flex;
          background: colors.$blue3;
          border-radius: 10px 10px 0 0;
          align-items: center;
          border-bottom: 2px solid colors.$blue1;
          padding: 9px;
          margin-top: 3px;

          svg {
            font-size: 18px;
          }

          input,
          input:focus {
            font-family: Rubik !important;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.28px;
            border: none;
            width: 88px;
            background-color: transparent;
            text-align: center;
            outline: 0;
            color: colors.$blue5;
          }
        }

        .text-filter-container {
          display: flex;
          border-radius: 10px 10px 0 0;
          margin-top: 3px;

          input,
          input:focus {
            border: none;
            outline: none;
            color: colors.$blue5;
            width: 95%;
            padding-left: 10px;
            font-family: Rubik;
            border-radius: 5px;
            box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
            background: colors.$blue3;
            height: 29px;
            width: 200px;
          }
        }

        #status-select {
          padding-top: 5px;
          padding-bottom: 0px;
          font-family: Rubik !important;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.28px;
          text-align: left;
          color: colors.$blue5;
          border: none;
          border-radius: 10px 10px 0px 0px;
          border-bottom: 2px solid colors.$blue1;
          background: colors.$blue3;
          width: max-content;
          min-width: 160px;
          height: 23px;
          font-size: 0.8em;

          .MuiSvgIcon-root {
            font-size: 20px;
            margin-top: 5px;
          }
        }

        .small {
          font-size: 0.8em;
          color: colors.$blue4;
          padding-top: 10px;
          padding-left: 5px;
        }
      }
    }

    .filter-btn {
      button {
        width: 140px;
        height: 30px;
        margin-right: 10px;
        border-radius: 100px;
        border: solid 1px colors.$blue4;
        font-family: Rubik;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 1.2px;
        text-align: center;
        color: colors.$blue4;
        background: white;
        transition: 150ms ease-in-out;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
          cursor: pointer;
          transform: scale(1.05);
          background: colors.$blue4;
          color: white;
        }
      }
    }
  }

  .filters-footer {
    margin-top: 10px;
    display: flex;

    .applied-filters {
      display: flex;
      width: 80%;

      .filter {
        margin-left: 15px;
        background-color: #fff;
        padding: 2px 10px 0 10px;
        border-radius: 20px;
        cursor: pointer;
      }
    }

    .clear-filters {
      text-transform: uppercase;
      font-size: 0.65em !important;
      margin-top: 3px;
      text-align: right;
      margin-right: 15px;
      width: 20%;

      &:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
    }
  }

  .MuiPaper-root:not(.MuiMenu-paper, .MuiDialog-paper) {
    font-size: 0.6em;
    padding: 0px;
    border-radius: 0px;

    svg {
      font-size: 20px;
    }

    .MuiTypography-subtitle1 {
      min-width: 100px;
      text-align: center;
      color: colors.$blue1 !important;
      letter-spacing: 1.6 !important;
      font-size: 1em !important;
      font-weight: bold !important;
    }

    .PrivatePickersSlideTransition-root {
      min-width: 130px;
      min-height: 130px;
      padding: 20px;
      padding-bottom: 0px;
      margin: 0 10px 10px 10px;
      border: 1px solid colors.$blue1;
    }

    .MuiTypography-root {
      width: 18px;
      color: colors.$blue4;
      font-size: 1em;
      height: fit-content;
    }

    .MuiDateRangePickerDay-root {
      width: 18px !important;
      height: 18px !important;
      padding: 2px;
    }

    .MuiButtonBase-root {
      width: 18px !important;
      height: 18px !important;
      color: colors.$blue1;
      text-align: left;
      padding-left: 1px;
    }

    .MuiDateRangePickerDay-rangeIntervalDayHighlight {
      background-color: colors.$blue3;
      padding-top: 0px;
      padding-bottom: 3px;
      min-width: 18px !important;
    }

    .MuiDateRangePickerDay-rangeIntervalDayHighlightStart {
      padding-left: 0px;
    }

    .Mui-selected {
      background-color: colors.$blue1 !important;
      color: #fff;
    }
  }
}
