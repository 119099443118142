@use "/src/utils/colors";
@use "../../styles/Variables-Functions.scss" as *;

html,
body {
  font-family: 'Open Sans', sans-serif;
}

$user-logo-name-navitem-background-color: #AF1E37;

@mixin sideBarNavItem {
  height: 40px;
  display: flex;
  align-items: center;
  transition: 300ms ease-in-out;
  text-decoration: unset;
  margin-top: 16px;
}

#side-nav-container {
  height: 100vh;
  width: 300px;
  max-width: 300px;
  flex-shrink: 0;
  position: static;
  transition: max-width 300ms ease-in-out; //cubic-bezier(0.175, 0.885, 0.32, 1.275);
  overflow: auto;
  background-color: $whiteColor;
  z-index: 999;
  box-shadow: 0px 4px 6px #C5CDD8;

  @media (max-width: 1080px) {
    transition: width 300ms ease-in;
  }

  &.collapsed {
    max-width: 72px;
  }

  #nav-items-container {
    padding-bottom: 80px;

    .nav-item {
      @include sideBarNavItem;
      cursor: pointer;

      @include sideBarItemStyle;

      .nav-collapse {
        padding-left: 34px;

        .nav-title-container {
          width: 192px;
          max-width: 192px;
          display: flex;
          justify-content: space-between;
        }
      }

      &.collapsed {
        width: 50%;

        .nav-collapsed {
          display: none;
        }
      }

      .nav-icon {
        height: 24px;
        width: 24px;
      }

      &.active {
        span {
          @include sideBarItemStyle(700);
        }

        .nav-icon {
          color: $blackColor;
        }

        svg {
          @include sidebaricon-active
        }

        border-left: 3px solid $user-logo-name-navitem-background-color;
        @include sideBarItemStyle(700);
        padding-left: 21px;
      }

      padding-left: 24px;

      &:hover:not(.collapse-navigation) {
        svg {
          @include sidebaricon-active
        }
      }
    }

    .side-nav-logo {
      padding-left: 16px;
      margin-top: 10px;

      .side-nav-logo-img {
        width: 44px;
        height: 42px;
      }

      .side-nav-logo-text {
        margin-left: 20px;
        @include sideBarItemStyle(700, 20px, 24px)
      }
    }

    .nav-sub-item-container {
      transition: max-height 300ms linear;
      max-height: 200px;
      overflow: hidden;
      margin-left: 34px;

      &.collapsed {
        max-height: 0;
      }

      .nav-sub-item {
        @include sideBarNavItem;

        cursor: pointer;

        margin-top: 10px;

        @include sideBarItemStyle;

        &.active {
          @include sideBarItemStyle(700);
        }

        span {
          white-space: nowrap;
          margin-left: 49.3px;
        }
      }
    }
  }

  #side-nav-bottom-container {
    bottom: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 300px;
    max-width: 300px;
    align-items: center;
    cursor: pointer;
    transition: max-width 300ms ease-in-out;
    // box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 70%);

    @include sideBarItemStyle(600, 20px, 24px, -0.02e, #2C4259);

    @media (max-width: 1080px) {
      transition: width 300ms ease-in;
    }

    .dropdown {
      background: #F1F4F7;
      color: #2C4259;
      padding: 10px;
      font-weight: 600;

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0.2em;

        svg {
          margin-right: 17px;
        }
      }
    }

    &.collapsed {
      max-width: 72px;

      .dropdown {
        div {
          svg {
            margin-right: 0;
            padding-left: 8px;
          }
        }
      }
    }

    #user-card-container {
      width: 100%;

      .top-nav-item {
        background-color: $whiteColor;
        padding: 20px;
        padding-left: 14px;
        padding-right: 0;

        .top-nav-item-inner {
          display: flex;
          align-items: center;

          span {
            display: flex;
            align-items: center;
            max-width: 198px;

            .button {
              margin-top: 10px;
              color: #2C4259;
            }
          }

          .user-logo-name {
            height: 44px;
            width: 44px;
            flex-shrink: 0;
            background-color: #E37472;
            border-radius: 25px;
            @include sideBarItemStyle(500, 20px, 24px, -0.02e, $whiteColor);
            display: flex;
            justify-content: center;
          }
        }
      }


    }
  }
}

.sidebar,
.toggle {
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.sidebar {
  display: flex;
  position: sticky;
  align-self: flex-end;
  top: 0;
  bottom: 0;
  z-index: 1000;
}

.sidebar-mobile {
  width: 100px;
  //height: 100%;
  position: sticky;
  align-self: flex-end;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
}

.toggle {
  position: absolute;
  left: 30px;
  top: 40px;
  background: #152229;
  text-align: center;
  color: white;
  cursor: pointer;
  padding-top: 15px;
  width: 30px;
  height: 60px;
  border-radius: 0 10px 10px 0;
  z-index: 0;

  .apex-logo-mini {
    width: 24px;
  }
}

.sidebar-collapsed {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  // width: 60px;
  position: sticky;
  align-self: flex-end;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  // height: 300px !important;
  // max-height: 100px;

  #side-nav-right-container.closed {
    transform: translateX(-210px);
    visibility: hidden;
    bottom: 0;
  }
}

.sidebar-collapsed .toggle {
  right: -5px;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}