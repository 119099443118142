@use "/src/utils/colors";

#dashboard-actions-menu {
  .MuiListItemText-root {
    padding-left: 10px;
  }

  .MuiMenuItem-root {
    color: colors.$blue5;
    svg {
      color: colors.$blue5;
    }
  }
}

#dashboard-page {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-flow: row wrap;
  justify-content: center;
  // margin-left: 1.5%;

  @media (max-width: 1080px) {
    margin-left: 0;
  }

  &.fullscreen {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba($color: colors.$grey4, $alpha: 0.5);
    margin-left: 0;
    overflow: hidden;
    .react-resizable-handle{
      display: none;
    }
    .widget-container{
      @media (max-width: 600px) {
        top: 0 !important;
       }
    }
  }

  #dashboard-content {
    width: 100%;

    span:first-child {
      font-weight: 500;
      padding-bottom: 10px;
    }

    .dashboard-header {
      background: #fff;
    font-family: 'Open Sans', sans-serif;
    padding: 10px 5px 5px 5px;
      .inpt-edit,.dropdown,.mark-default, .action-items{
        vertical-align: middle;
        display: inline-block;
        padding:0 0 5px;
       
      }
      
      .action-items{
        float: right;
        
      }
      @media (max-width: 600px) {
        .inpt-edit,.dropdown{
        display: block;
        }
        .inpt-edit input{
          padding: 3px 0;
        }
        .action-items{
          float: right;
        }
       }
       
      .mark-default{
        @media (max-width: 740px) {
          min-width: 50%;
        }
      }
      .dashboard-actions-container {
       text-align: right;
        .icon {
          text-align: right;
          padding-right: 10px;
          color: colors.$blue5;
          cursor: pointer;
          display: flex;
          font-weight: 500;

          span {
            margin-top: 3px;
            margin-left: 3px;
          }

          svg {
            color: colors.$blue5;
            cursor: pointer;
          }
        }
      }
      > svg {
        align-self: end;
        margin-bottom: 8px;
        margin-left: 5px;
      }
    }

    .dashboard-grid {
      .no-widgets {
        margin-top: 20%;
        text-align: center;
      }
    }

    #dashboard-select {
      padding-top: 5px;
      padding-bottom: 0px;
      font-family: Open Sans, sans-serif;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.28px;
      text-align: left;
      border: none;
      width: max-content;
      min-width: 160px;
      margin-top: 5px;
      height: 30px;
      color: var(--neutral-01, #58687C);
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      vertical-align: middle;
      @media (max-width: 600px) {
        width:-webkit-fill-available;
        // font-size: 12px;
        padding-left: 5px;
       }

      
    }
    .MuiSvgIcon-root {
      height: 22px;
      width: 22px;
    }

    .widget-container {
      border-radius: 4px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
      background-color: #fff;
      margin-bottom: 0 !important;
      height: 100%;
      transition: height 200ms ease;

      .widget-ellipsis {
        display: flex;
        position: absolute;
        right: 5px;
        z-index: 9999;

        span {
          margin-right: 5px;
        }

        .widget-actions-container {
          line-height: 0;
          .icon {
            margin-top: 00px;

            svg {
              color: #00aaa9;
              cursor: pointer;
              margin-top: 0px;
            }
          }

          .widget-actions-dropdown {
            background-color: #fff;
            border-radius: 10px;
            max-width: 160px;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px;
            margin-top: 20px;
            padding-top: 0px !important;
            svg {
              margin-top: 2px;
            }

            span {
              margin-left: 2px;
            }
          }
        }

        svg {
          float: right;
          cursor: pointer;
          color: colors.$blue4;
          font-size: 24px;
        }
      }

      .widget-inner-container {
        padding-top: 35px;
        padding-left: 10px;
        padding-right: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 35px);

        .widget-body {
          width: 100%;
          height: calc(100% - 35px);

          #error-container {
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            svg {
              color: colors.$red1;
              height: 1.5em;
              width: 1.5em;
            }
            span {
              margin-left: 10px;
              color: colors.$red1;
              font-style: italic;
            }
          }

          .report-style {
            iframe {
              width: 100% !important;
              height: 100% !important;
              // position: absolute;
              border: none;
            }
          }
          #progress-container {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            justify-content: center;
          }
        }

        .widget-footer {
          height: 20px;
          font-family: Rubik;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.28px;
          color: #00aaa9;
          position: relative;
          margin-top: 10px;

          .inactive {
            color: colors.$grey5;
            cursor: default;
          }

          div {
            cursor: pointer;
            position: absolute;

            svg {
              font-size: 1em;
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .report-style {
    height: 100%;
    width: 100%;
  }

  .panel {
    padding: 20px;
    background-color: colors.$blue2;
  }
}

.star-container {
  width: 100%;
  text-align: right;
}
.myStarIcon {
  color: colors.$blue1;
  margin-left: auto;
}

.myStarBorderIcon {
  color: colors.$blue1;
  margin-left: auto;
  opacity: 0.5;
}

.myMenuItem {
  // .myStarIcon {
  //   visibility: visible;
  // }
  &:hover {
    .myStarBorderIcon {
      opacity: 1;
    }
  }

  .mySettingsIcon {
    color: colors.$blue1;
    margin-right: 20px;
  }
  .menu-title{
    width: 100%;
    padding-right: 5px;
  }
}

.react-grid-layout-fullscreen {
  position: absolute !important;
  // left: 0;
  // top: 0;
  z-index: 999;
  height: 100vh !important;
  width: 100vw !important;
  overflow: hidden;
  // background-color: rgba(0, 0, 0, 0.5);

  .react-grid-item.fullscreen {
    z-index: 2;
    // transition: all 200ms ease;
    // transition-property: left, top;
    // width: calc(100vw - 10px) !important;
    // height: calc(100vh - 10px) !important;
    // top: 5px;
    // left: 5px;
  }
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item img {
  pointer-events: none;
  user-select: none;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
  @media (max-width: 600px) {
    width: 95% !important;
   }
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}
.react-grid-item:hover {
  // z-index: 10;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: #00aaa9;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border-radius: 10px;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 10px;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 10px;
  right: 0;
  cursor: se-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  //margin-top: -10px;
  cursor: ew-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  //margin-left: -10px;
  cursor: ns-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0;
  margin-right: 5px;
  bottom: 10;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

#dsh-actions-button:not(.disabled) {
  font-family: Rubik;
  border: 1px solid colors.$blue2;
  width: 130px;
  border-radius: 100px;
  transition: 150ms ease-in-out;
  color: colors.$blue2;
  font-weight: 400 !important;
  cursor: pointer;
  letter-spacing: 1.4px;
  line-height: 1;
  text-transform: uppercase;
  font-size: 0.8em !important;
  padding-left: 20px;
  padding-right: 10px;
}

#dsh-actions-button:hover {
  transform: scale(1.05);
}

#widget-actions-button,
#widget-actions-button:focus {
  min-width: 10px !important;
  background-color: transparent !important;
}
.dashboard-header
.dashboard-dropdown{
  background: #fff;
  border-radius: 4px;
  border: 1px solid var(--neutral-08, #D6DADF);
  color: var(--neutral-01, #58687C);
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.inpt-dsbd-title{
  
  border: none;
  vertical-align: middle;
  padding: 3px 6px;
  color: colors.$blue1;
  font-family: Open Sans,sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.2px;
  border-radius: 4px;
  outline: 0;
  width: -webkit-fill-available;
  
  &:focus,&:focus-visible{
    border: 1px dashed var(--neutral-05, #8EA0B5);
  }
}
.default-label{
  color: var(--neutral-02, #68788B);

/* Para14px/Regular */
font-family: Open Sans, sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 185.714% */
}
.dashboard-select {
  width: 100%;
  @media (max-width: 600px) {
    width: 100%;
   }
   @media (min-width: 600px) and (max-width: 700px){
    width: max-content;
   }
   @media (min-width: 1200px) {
    width: max-content;
   }
  .MuiInputBase-root{
  outline: 0;
  // width: max-content;
  &:hover{
    border: none;
  }
}
}
.default-dshbd-icon{
  border-radius: 2px;
  border: 1px solid var(--common-blue-link, #165CE8);
  background: #EDF3FF;
  color: var(--common-blue-link, #165CE8);
font-family: Open Sans, sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 20px; 
display: inline-flex;
padding: 0px 8px;
align-items: flex-start;
}
li.Mui-selected {
  color: var(--common-dark-gray, #374149);
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 26px; /* 185.714% */
}
.dashboard-actions-container{
  button{
    border-radius: 4px;
    border: 1px solid var(--neutral-08, #D6DADF);
    display: inline-flex;
    padding: 8px;
    align-items: flex-start;
    gap: 4px;
  }
  svg{
    width: 18px;
    height: 18px;
  }
  .more-options{
    color: colors.$blue1;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 166.667% */
    display: inline-block;
  }
}

.dashboard-header .MuiGrid-root:not(:first-child){
  @media (max-width: 600px) {
   padding-top: 5px !important;
  }
}
.no-dashboard{
  position: absolute;
  top: 50%;
}
.inpt-edit.fullWidth{
  @media (min-width: 1600px) {
    min-width: 26%;
}
@media (min-width: 1200px) and (max-width: 1600px) {
  min-width: 34%;
}
@media (min-width: 930px) and (max-width: 1200px) {
  min-width: 48%;
}
@media (min-width: 1160px) and (max-width: 1200px) {
  min-width: 36%;
}
@media (min-width: 780px) and (max-width: 930px) {
  min-width: 58%;
}
@media (max-width: 780px) {
  min-width: 100%;
}

}
