@use "/src/utils/colors";
@mixin input-element{
    border: 1px solid var(--neutral-08, #D6DADF) !important;
    color: var(--common-dark-gray, #374149);
    font-size: 14px !important;
    font-family: Open Sans, sans-serif;
    font-style: normal ;
    font-weight: 400;
    line-height: 1.5;
    display: flex;
    padding: 7px 12px !important;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    width: -webkit-fill-available;
    background-color: var(--neutral-10, #FFF) !important;
    outline: 0;
    height: auto;
    box-shadow: none;
}
@mixin select-element{
    display: flex;
    padding: 7px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    background: #FFF;
    border-radius: 4px;
    border: 1px solid var(--neutral-08, #D6DADF);
}
#report-form,#clone-form,#share-dashboard-dialog,.share-form,.subscription-form,.recurrence-form,.report-category,.subscribers-form{

.select-lable {
    color: colors.$blue1;
    font-size: 14px;
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.32px;
    text-transform: none;
    padding-top: 10px;
    .add-new {
        float: right;
        cursor: pointer;
        svg{
        width: 13px;
        height: 14px;
        vertical-align: middle;
        padding-bottom: 4px;
        padding-right: 4px;
    }
    p{
        color: var(--main-primary-03, #2C4259);
        font-family: Open Sans,sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: -0.24px;
        display: inline;
    }
}
.mandatory{
    color:var(--Error-color-code, #E2494F);
}
}
input, input:focus {
    @include input-element()
    
}
.MuiCheckbox-root input{
    height: 24px;
}
.MuiGrid-container{
    padding: 4px 0;
    
}
    .MuiInputBase-root{
        width: 100%;
        padding: 0;
        .MuiInputBase-input{
            em{
                font-style: normal;
                font-size: 15px;
            }
            @include input-element
            
        }
    }
    textarea {
        width: -webkit-fill-available;
        @include input-element
        
    }
.field-container{
    padding: 4px 0;
    em{
        font-style: normal;
        font-size: 15px;
    }
}
.error-txt {
    font-size: 0.75rem;
    color:#E2494F;
    display: block;
}
.mandatory{
    color:#E2494F
}
.dialog-footer{
    padding: 20px 0px 0px 0px;

}
.info{
    font-size: 0.75rem !important;
    color: #68788b;
    display: block;
}

.clone-small{
    color: var(--neutral-01, #58687C);
    font-family: Inter,Open sans,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.14px;
}
.share-subtitle{
    color: #000;
    font-family: Open Sans,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
}
.label-block{
    line-height: 2;
    div{
        display: inline-block;
    }
    .email-notification-label{
        color: var(--neutral-02, #68788B);
        font-family: Open Sans,sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        float: right;
        padding:2px;
        span{
            padding: 0;
        }
        @media (min-width: 600px) and (max-width: 793px) {
            float: initial;
        }
    }
}
.email-container{
    padding: 0px 0 5px;
}
.userlist-label{
    color: var(--common-blue-link, #165CE8);
    font-family: Open Sans,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    text-decoration-line: underline;
    text-align: right;
    cursor: pointer;
    outline: 0;
}
.shared-with{
    padding: 10px  0;
    .userlist{
        color: var(--main-primary-01, #142330);
        font-family: Open Sans,sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.32px;
        padding: 5px 5px 5px;
        div{
            display: inline-block;
        }
        svg{
            float: right;
            cursor: pointer;
        }
        @media (min-width: 600px) and (max-width: 900px) {
            font-size: 14px;
            svg{
                width: 22px;
                height: 22px;
            }
        }
    }
}

//subscription-from
.report-filters .item{
    border-radius: 4px;
    border: 1px solid #D6DADF;
    background: var(--neutral-10, #F1F4F7);
    display: inline-flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    p{
        color: var(--neutral-04, #758496);
        font-family: Open Sans,sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 162.5% */
    }
}

.export-type{
    .Mui-selected{
      svg,svg path{
        fill: #fff;
      }
      color: #fff;
      background-color: var(--main-primary-03, #2C4259);
      &:hover {
        background-color: var(--main-primary-03, #2C4259);
    }
    }
    button{
        color: #000;
    }
    .MuiToggleButtonGroup-grouped:not(:first-of-type) {
        margin-left: 0;
    }
  }

}
.rf-menu-item-inner {
    display: block;
    max-width: 400px;
    min-width: 120px;
    width: 100%;
    color: var(--common-dark-gray, #374149);
    div:first-of-type {
      overflow: hidden;
      width: calc(100% - 1.5em);
      text-overflow: ellipsis;
      color: var(--common-dark-gray, #374149)
    }
    div.inline-container{
        display: inline-block;
    }
    .subtitle {
      font-size: 0.8em;
      color: var(--common-dark-gray, #374149)
    }
    svg {
      margin-left: auto;
    }
  }
.report-category{

.MuiDialog-container{
    height: auto;
    .dialog-title{
        text-transform: none;
        border-bottom: 1px solid #D6DADF;
        color: colors.$blue1;
        font-family: Open Sans,sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700 !important;
        line-height: 24px;
        letter-spacing: normal;
        padding: 10px 15px 5px 30px;
        .close-icon{
            float: right;
            cursor: pointer;
        }
        @media screen and (max-width: 600px) {
            min-width: 250px;
        }
    }
    .dialog-content{
        input{
            em{
                font-style: normal;
                font-size: 15px;
            }
            @include input-element
            
        }
    }
    .dialog-footer{
        padding: 0 15px 20px 30px;
    }
}

.btn-delete{
    border-radius: 4px;
        border: 1px solid var(--error-color-code, #E2494F);
        background: var(--error-color-code, #E2494F);
        color: #FFF;
        font-size: 14px ;
        font-family: Open Sans, sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: -0.36px;
        text-transform: none;
        display: inline-flex;
        height: 40px;
        padding: 12px 16px;
        align-items: center;
        gap: 8px;
        &:hover {
            transform: scale(1.1);
            background-color: #E2494F;
        }
}

}
.subscription-form .MuiToggleButton-root {
    display: block;
    width: auto;
    padding: 8px 15px;
    border-radius: 4px;
    border: 1px solid var(--neutral-08, #D6DADF);
    background: var(--neutral-10, #F1F4F7);
    color: #EBEFFF;
    text-align: center;
    font-family: Open Sans,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    svg{
    vertical-align: middle;
    padding: 0 5px;
    }
    
}
.recurrence-form,.subscribers-form {
     .MuiFormControl-root{
    width: 100%;
    .MuiOutlinedInput-root{
        padding: 0px 15px 5px 0px;
        &:hover,&:focus{
            .MuiOutlinedInput-notchedOutline{
                border-color: #D6DADF;
            }
            outline: none;
        }
        input{
            border: none !important;
        }
    }
   
}
.daily-recurrence .MuiInputBase-root{
    .MuiSelect-outlined{
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block !important;
        
    }
    svg{
        right: 0 !important;
    }
}
.weekly-recurrence .day {
    width: 35px;
    height: 35px;
    border-radius: 31px;
    background: var(--neutral-10, #F1F4F7);
    margin-right: 0.54em;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: center;

    /* Para16px/Regular */
    font-family: Open Sans,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    margin-bottom: 0.5em;
}
.weekly-recurrence .day.chosen{
    background-color: #2D4F6C;
    color:#FFF
}
.monthly-recurrence{
    .MuiFormControlLabel-root{
        display: flex;
        flex-direction: row;
        height: 3.5em;
        .MuiTypography-root{
            width: -webkit-fill-available;
        }
    }
}

}
.subscribers-form{
    .frequency-right .subscribers-info{
        background-color: #f8fafc;
        padding: 16px;
        border-radius: 4px;
        margin-top: 15px;
        svg{
            padding-right: 10px;
            vertical-align: middle;
        }
        p{
            display: inline;
            color: #000;
            font-family: Open Sans,sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
        }
    }
}
    