@use "/src/utils/colors";

@media print {
  #app-inner-container {
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    z-index: 1001 !important;
    padding: 0 !important;
    background: white !important;

    iframe {
      position: absolute !important;
      width: 90vw !important;
      height: 90vh !important;
    }

    #rpt-actions-menu {
      display: none !important;
      .MuiPaper-root {
        display: none !important;
      }
    }
    .report-container {
      position: absolute !important;
      top: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      height: 100%;
    }
    #report-page-wrapper {
      height: 100%;
      padding: 0 !important;
    }
    #environment-banner {
      display: none !important;
    }
  }
}

#rpt-actions-menu {
  .MuiListItemText-root {
    padding-left: 10px;
  }

  .MuiMenuItem-root {
    color: colors.$blue5;
    svg {
      color: colors.$blue5;
    }
  }
}

#rpt-actions-button:not(.disabled) {
  font-family: Rubik;
  border: 1px solid colors.$blue2;
  //width: 160px;
  border-radius: 100px;
  transition: 150ms ease-in-out;
  color: colors.$blue2;
  font-weight: 400 !important;
  cursor: pointer;
  // letter-spacing: 1.4px;
  // line-height: 1;
  text-transform: uppercase;
  font-size: 0.8em !important;
  // padding-left: 20px;
  // padding-right: 10px;
}
.report-actions{
  text-align: right;
  padding: 2px;
}
#rpt-actions-button {
  font-family: Rubik;
  border: 1px solid colors.$blue2;
  //width: 160px;
  border-radius: 100px;
  transition: 150ms ease-in-out;
  color: colors.$blue2;
  font-weight: 400 !important;
  cursor: pointer;
  // letter-spacing: 1.4px;
  // line-height: 1;
  text-transform: uppercase;
  font-size: 0.8em !important;
  padding: 0;
  padding-left: 10px;
  padding-right: 5px;
}

#rpt-actions-button:hover {
  transform: scale(1.05);
}

#rpt-actions-button:disabled {
  border: 1px solid colors.$grey5;
  color: colors.$grey5;
  cursor: not-allowed;
}

#report-page-wrapper {
  flex-grow: 1;
  // padding-top: 10px;
  // padding-bottom: 60px;

      height: 100%;
      // margin-left: 1.5%;

  @media (max-width: 1080px) {
    margin-left: 0;
  }

  .fullscreen {
    height: 100%;
  }

  span:hover {
    scale: (1.01);
  }

  #report-page-main {
    background: white;
    border-radius: 10px;

    height: 100%;
    // box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px;
    display: flex;
    flex-direction: column;
    // min-width: 600px;
    position: relative;

    @media (max-width: 1080px) {
      min-height: 100%;
    }

    .MuiCircularProgress-colorPrimary {
      color: colors.$blue4 !important;
    }

    .edit-mode {
      background-color: #00aaa9;
      padding: 10px;
      border-radius: 10px 10px 0px 0px;
      color: #fff;
    }

    #header-container {
      display: flex;
      padding-top: 20px;
      padding-bottom: 10px;
      position: relative;
      padding-left: 40px;
      padding-right: 40px;

      .report-title {
        flex-grow: 1;
        font-family: Rubik;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.19;
        letter-spacing: 0.32px;
        font-weight: 500;
        text-align: left;
        color: colors.$blue4;
      }

      a {
        text-decoration: none;
      }

      .close {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        a,
        div {
          font-family: Rubik;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 1.2px;
          text-align: center;
          color: colors.$blue4;
          margin-left: 50px;

          &:hover {
            cursor: pointer;
          }

          > * {
            margin-left: 3px;
            margin-right: 3px;
          }

          svg {
            font-size: 24px;
          }
        }
      }
    }

    .report-container {
      // padding-bottom: 30px;
      flex-grow: 1;
      // padding-left: 40px;
      // padding-right: 40px;

      height: 100%;
      .dashboard-btn {
        display: flex;
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 15px;
        align-items: center;
        color: colors.$blue1;

        span {
          svg {
            font-size: 18px;
            margin-right: 10px;
            cursor: pointer;
            margin-top: 3px;
          }
        }
      }

      .report-wrapper {
        display: flex;
        height: 100%;

        #error-container {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          svg {
            color: colors.$red1;
            height: 1.5em;
            width: 1.5em;
          }
          span {
            margin-left: 10px;
            color: colors.$red1;
            font-style: italic;
          }
        }

        .report-style2 {
          height: 100%;
          width: 100%;
          iframe {
            border: none;
          }
        }

        #progress-container {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: center;
        }
      }
    }

    .button-container {
      display: flex;
      border-top: 2px solid colors.$blue4;
      padding-top: 10px;
      position: relative;
      align-content: center;
      align-items: center;

      .fullscreen-icon-container {
        color: colors.$blue4;
        width: 20px;
        height: 100%;
        &:hover:not(.disabled) {
          cursor: pointer;
        }
        &.disabled {
          color: colors.$grey3;
        }
        margin-right: 20px;
      }

      .click-preventer {
        position: absolute;
        top: -2px;
        right: 110px;
        width: 110px;
        height: 36px;
        margin: unset;
        z-index: 10;
      }

      button {
        font-family: Rubik;
        border: 1px solid colors.$blue2;
        background-color: colors.$blue2;
        width: 160px;
        border-radius: 100px;
        box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.3);
        transition: 150ms ease-in-out;
        color: #e8e8e8;
        font-weight: 400;
        cursor: pointer;
        letter-spacing: 1.4px;
        line-height: 1.8;
        text-transform: uppercase;

        div {
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            padding-right: 5px;
            font-size: 20px;
          }
        }
      }

      button:hover {
        transform: scale(1.05);
      }

      button:disabled {
        border: 1px solid colors.$grey5;
        background-color: colors.$grey5;
        cursor: not-allowed;
      }

      a {
        text-decoration: none;
      }

      .save-btn {
        width: 170px;
        text-transform: uppercase;
        font-weight: 400 !important;
      }

      .link {
        font-family: Rubik;
        line-height: 2.5;
        letter-spacing: 1.68px;
        text-align: left;
        color: colors.$grey5;
        text-decoration: none;
        margin-left: 40px;
        text-transform: uppercase;
      }

      .link:hover {
        opacity: 0.8;
      }
    }
  }
}
.displayArea{
  top: 0;
}