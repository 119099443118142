@use "/src/utils/colors";

#type-outer-container {
  > div:first-child {
    font-weight: 500;
    letter-spacing: 0.22px;
  }

  #type-inner-container {
    display: flex;
    width: 200px;
    height: 35px;
    margin-top: 5px;
    background: colors.$blue3;
    border-radius: 10px 10px 0 0;
    align-items: center;
    border-bottom: 2px solid colors.$blue1;
    position: relative;

    svg {
      color: rgba(0, 0, 0, 0.54);
      margin-top: 5px;
      margin-right: 7px;
    }

    #chosen-report-type {
      flex-grow: 1;
      padding: 0 10px;
    }

    &:hover {
      cursor: pointer;
    }

    #report-dropdown {
      position: absolute;
      top: calc(100% + 4px);
      width: 100%;
      background: white;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px;
      $radius: 7px;
      border-radius: $radius;
      overflow: hidden;
      z-index: 999 !important;

      // The dropdown items
      > div:nth-child(odd) {
        padding: 5px 15px;
        text-align: left;

        &:hover {
          background: rgb(233, 245, 255);
        }
      }

      // The dividers
      > div:nth-child(even) {
        border-top: 0px solid rgb(181, 198, 212);
        margin-left: 10%;
        width: 80%;
      }
    }
  }
}
