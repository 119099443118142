@use "/src/utils/colors";

#status-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    to bottom,
    colors.$blue1,
    colors.$blue5 55%,
    colors.$blue5
  );

  z-index: 999;

  #status-container {
    position: absolute;
    left: 45%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 920px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;

    @media screen and (max-width: 1000px) {
      & {
        left: 55%;
        top: 40%;
        .status-code-container {
          left: 56% !important;
          top: 0 !important;
          h1 {
            font-size: 220px !important;
          }
        }
        .status-message-container {
          position: relative;
          img {
            margin-top: 100px !important;
          }
          h2 {
            margin-top: 150px !important;
          }
        }
      }
    }

    @media screen and (max-width: 700px) {
      & {
        left: 55%;
        .status-code-container {
          left: 56% !important;
          h1 {
            font-size: 150px !important;
          }
        }
      }
    }

    .status-code-container {
      position: absolute;
      top: -40%;
      left: 75%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      z-index: -1;

      h1 {
        margin: 0 0 18px 68px;
        opacity: 1;
        font-family: Rubik;
        font-size: 350px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 7px;
        text-align: left;
        color: colors.$blue5;
      }
    }

    .status-message-container {
      position: relative;
      width: 40%;
      text-align: left;
      .apex-logo {
        width: 80%;
        margin-top: 50px;
      }

      h2 {
        font-family: Rubik;
        font-size: 28px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.86;
        letter-spacing: 0.56px;
        text-align: left;
        color: colors.$grey1;
        padding-left: 10%;
      }

      p {
        font-family: Rubik;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.36px;
        text-align: left;
        color: colors.$grey1;
        padding-left: 10%;
        padding-top: 5%;
        padding-bottom: 5%;
        min-height: 100px;
      }

      #action-button-container {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        width: 90vw;

        .action-button {
          font-family: Rubik;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.5;
          letter-spacing: 1.4px;
          text-decoration: none;
          text-transform: uppercase;
          text-align: center;
          color: colors.$blue1;
          -webkit-transition: 0.2s all;
          transition: 0.2s all;
          padding: 11.8px 48.5px 11.2px 48.5px;
          border-radius: 100px;
          background-color: colors.$grey1;
          box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.3);
          margin-left: 25px;
          margin-bottom: 20px;
          min-width: 100px;

          &:hover {
            background-color: colors.$blue4;
            cursor: pointer;
          }
        }
      }
    }
  }
}
