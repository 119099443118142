@use "/src/utils/colors";

#contact-page {
  display: flex;
  height: 100%;
  width: 80%;
  @media (max-width: 900px) {
    width: 100%;
    .help-section{
      display: none;
    }
}

  #contact-form-outer {
    width: 100%;

    #contact-form-main {
      background: white;
      border-radius: 10px;
      padding-left: 40px;
      padding-right: 40px;
      margin-top: 10px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px;
      max-width: 900px;
      min-width: 600px;
      //   margin-left: 1.5%;
      margin: auto;

      @media (max-width: 1080px) {
        margin-left: 0;
      }

      .title {
        color: colors.$blue4;
        letter-spacing: 0.32px;
        line-height: 35px;
        width: 100%;
        font-weight: 500;
        padding-top: 20px;
        padding-bottom: 10px;
        margin-bottom: 20px;
      }

      .form-container {
        padding-top: 20px;
        padding-left: 0px;

        .MuiGrid-item {
          padding-top: 16px;
        }

        .label-cell {
          color: colors.$blue5;
          letter-spacing: 0.28px;
          width: 100%;
        }

        .control-cell {
          position: relative;
          width: 100%;
          padding-top: 10px;
          padding-left: 0;
          min-height: 60px;

          .loading {
            position: absolute;
            left: -23px;
            top: 24px;
          }
        }

        input {
          border: none;
          height: 28px;
          outline: none;
          color: colors.$blue5;
          margin-right: 5px;
          width: calc(100% - 10px);
          padding-left: 10px;
          font-family: Rubik;
          border-radius: 5px;
          box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
          background-color: rgba(85, 136, 163, 0.2);
          height: 40px;
          &:focus {
            border: 2px solid colors.$blue4;
          }

          &:disabled {
            background-color: #e8e8e8;
          }
        }

        input:disabled {
          background-color: #e8e8e8;
        }

        .input-error {
          border: 1px solid #a04b5a;
        }

        .select {
          font-family: Rubik;
          letter-spacing: 0.28px;
          text-align: left;
          color: colors.$blue5;
          height: 35px;
          padding-top: 5px;
          width: 100%;
          border-radius: 5px;

          svg {
            position: absolute;
            right: 10px;
            top: 12px;
            font-size: 20px;
            color: colors.$blue5;
          }
        }

        .Mui-disabled {
          -webkit-text-fill-color: colors.$blue5;
        }

        textarea,
        textarea:focus {
          border-radius: 3px;
          border: 1px solid #ced4da !important;
          height: 140px;
          margin-top: -5px;
          outline: none;
          color: colors.$blue5;
          width: calc(100% - 10px);
          padding-left: 10px;
          font-family: Rubik;
          resize: none;
          box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
          background-color: rgba(85, 136, 163, 0.2);
        }

        .error {
          position: absolute;
          font-family: Rubik;
          color: #a04b5a;
          left: 5px;
          bottom: -2px;
          display: block;
        }
      }

      .btn-row {
        width: 100%;
        padding-bottom: 40px;
        margin-top: 40px;
        clear: both;
      }

      .save-button {
        font-family: Rubik;
        border: 1px solid colors.$blue2;
        background-color: colors.$blue2;
        height: 40px;
        width: 148px;
        border-radius: 100px;
        box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.3);
        transition: 150ms ease-in-out;
        text-transform: uppercase;
        color: #e8e8e8;
        font-weight: 400;
        cursor: pointer;
        letter-spacing: 1.4px;
        line-height: 2.5;
        &:focus {
          border: 2px solid colors.$blue4;
        }

        div {
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            color: colors.$blue5;
          }
        }

        &:hover {
          border: 1px solid colors.$blue1;
          background-color: colors.$blue1;
        }

        &:disabled {
          border: 1px solid colors.$grey5;
          background-color: colors.$grey5;
          cursor: not-allowed;
        }
      }

      .cancel-button {
        background: transparent;
        font-family: Rubik;
        font-weight: 400;
        line-height: 2.5;
        letter-spacing: 1.68px;
        text-align: left;
        color: colors.$grey5;
        text-decoration: none;
        margin-left: 40px;
        text-transform: uppercase;
        &:focus {
          border: 2px solid colors.$blue4;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .p-25{
    padding:1.5rem;
  }
}

.cf-menu-item-inner {
  display: flex;
  flex-flow: row wrap;
  max-width: 400px;
  min-width: 120px;
  width: 100%;
  div:first-of-type {
    overflow: hidden;
    width: calc(100% - 1.5em);
    text-overflow: ellipsis;
  }
  div.subtitle {
    font-size: 0.8em;
    font-style: italic;
    color: colors.$grey3;
  }
  svg {
    margin-left: auto;
  }
}
.contact-body{
  overflow: hidden;margin: 12px;
  width: 70%;background: rgb(255, 255, 255);
  box-shadow: 0px 2px 4px -2px #1018280F;
  box-shadow: 0px 4px 8px -2px #1018281A;
  gap: 0px;border-radius: 8px;
}
.form-section{
    width: 59%;
    display: inline-block;
    position: relative;
    .form-title{
      font-family: Open Sans, sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: -0.01em;
      text-align: left;
      position: relative;
      color: #142330;
    }
}
.help-section{
    background: #DBE6F0;
    border-left: 4px solid #AF1E37;
    height: 30rem;
    padding: 1rem;
    display: inline-block;
    .help-title{
      font-family: Open Sans, sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: -0.01em;
      text-align: left;
      vertical-align: middle;
      margin-top: 25%;
    }
    small{
      font-family: Open Sans, sans-serif;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
    .c-logo{
      text-align: right;
    padding: 6rem 0rem;
    }
}
