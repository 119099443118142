@use "/src/utils/colors";

@mixin tableColumnProperties {
  > div {
    padding: 10px;

    &:nth-child(1) {
    }
    &:nth-child(2) {
      width: 100px;
    }
    &:nth-child(3) {
      width: 70px;
    }
  }
}

#subscription-admin-main {
  display: flex;
  flex-direction: column;

  margin-left: 1.5%;

  @media (max-width: 1080px) {
    margin-left: 0;
  }

  .control-wrapper {
    position: relative;
    z-index: 1;
    display: flex;

    .row-count-container {
      justify-content: flex-end;
      width: 100%;
      display: flex;
      margin-left: 30px;

      .page-count-wrapper {
        align-self: flex-end;
        .count-label {
          padding-bottom: 5px;
          font-weight: 500;
        }

        #count-value-select {
          padding-top: 5px;
          padding-bottom: 5px;
          font-family: Rubik;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.28px;
          text-align: left;
          color: colors.$blue5;
          border: none;
          border-radius: 10px 10px 0px 0px;
          border-bottom: 2px solid colors.$blue1;
          background: colors.$blue3;
          width: 60px;
        }
      }
    }
  }

  #subscription-list-title {
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.32px;
    color: colors.$blue4;
    padding-top: 20px;
    padding-bottom: 13px;
  }

  #subscription-header-container {
    display: flex;
    align-items: flex-end;
    margin-top: 20px;

    > div {
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.26px;
      line-height: 17px;

      div.sortable {
        width: fit-content;
        cursor: pointer;
        display: flex;
        justify-content: center;
        svg {
          font-size: 18px;
          color: #a19090;
          margin: 0px;
          margin-left: 5px;
        }

        svg.selected {
          color: colors.$blue5;
        }

        svg.reverse {
          transform: scale(1, -1);
        }
      }
    }
  }

  #subscription-list-container {
    background: white;
    border-radius: 10px;
    margin-top: 10px;
    padding: 40px;
    padding-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px;

    @media (max-width: 1080px) {
      padding: 20px;
      margin-top: 30px;
    }

    .spinner {
      margin: 50px auto;
      text-align: center;

      .MuiCircularProgress-colorPrimary {
        color: colors.$blue4 !important;
      }
    }

    .no-subscriptions,
    .spinner {
      font-size: 1.5em;
      margin: 50px auto;
      text-align: center;
      color: colors.$blue4 !important;
    }

    .subscriptions-list-wrapper {
      padding-right: 20px;
      margin-right: -20px;
      height: 50vh;
      position: relative;
      overflow: auto;
      scrollbar-color: colors.$blue4 rgba(149, 148, 148, 0.3);
      scrollbar-width: thin;

      .subscription-component {
        background: rgb(233, 245, 255);
        border-radius: 10px;
        margin: 5px 0;
        padding-left: 0px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.6;
        letter-spacing: 0.26px;

        &.inactive {
          background: colors.$grey2;
          font-weight: 100;
          color: colors.$grey3;

          svg {
            color: colors.$grey3;
          }
        }

        .subscription-component-details {
          display: flex;
          align-items: center;

          a {
            text-decoration: none;
          }

          svg {
            padding-top: 10px;
            padding-bottom: 0px;
            transition: transform 150ms ease-in-out;
            &:hover {
              transform: scale(1.1);
            }
            &:active {
              transform: scale(0.9);
            }
          }

          div.run-btn {
            border: 1px solid colors.$blue1;
            height: 30px;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            padding: 0 10px;
            transition: 150ms ease-in-out;
            font-weight: 500;

            span {
              color: colors.$blue1;
            }

            &:hover {
              cursor: pointer;
              background: colors.$blue1;
              transform: scale(1.1);
              span {
                color: white;
              }
            }

            &:active {
              cursor: pointer;
              transform: scale(0.9);
            }
          }
        }

        .subscription-component-params {
          display: flex;
          align-items: center;
          border-top: 1px solid rgba(85, 136, 163, 0.3);
          width: 98%;
          padding: 10px 10px 10px 10px;
          line-height: 1.2;

          div:first-child {
            width: 70%;
          }

          span {
            margin-right: 30px;
          }
        }
      }
    }
  }

  .first-column {
    width: 120px;
    text-align: center;
    font-weight: 500;
    padding-left: 1%;

    div {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 1%;
      svg {
        margin-top: -10px;
        margin-left: 5px;
      }
    }
  }
  .second-column {
    width: 20%;
    padding-left: 1%;
    line-height: 1;
    overflow-wrap: break-word;
  }
  .third-column {
    width: 30%;
    padding-left: 1%;
    line-height: 1;
  }
  .fourth-column {
    width: 200px;
    padding-left: 1%;
    line-height: 1;
  }
  .fifth-column {
    width: 200px;
    padding-left: 1%;
    line-height: 1;
  }

  .sixth-column {
    width: 10%;
    padding-left: 1%;
    padding-right: 1%;
    line-height: 1;
    //text-align: center;
  }

  .pagination-wrapper {
    padding-top: 10px;
    display: flex;
    align-content: center;
    justify-content: center;

    div {
      margin-right: 20px;
    }

    .btn {
      border: 2px solid #e8e8e8;
      border-radius: 6px;
      padding-left: 5px;
      padding-right: 3px;
      cursor: pointer;

      svg {
        margin-top: 5px;
      }
    }

    .disabled {
      pointer-events: none;
    }

    .txtbox {
      margin-top: 5px;
      margin-right: 10px;

      input,
      input:focus {
        border: none;
        outline: none;
        color: colors.$blue5;
        width: 32px;
        font-family: Rubik;
        border-radius: 5px;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
        background-color: rgba(85, 136, 163, 0.2);
        height: 25px;
        border: 1px solid colors.$blue4;
        text-align: center;
      }
    }

    .total-pages {
      padding-top: 10px;

      span {
        padding-left: 5px;
      }
    }
  }
}
