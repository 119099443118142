@use "/src/utils/colors";
@use "../../styles/Variables-Functions.scss" as *;

.home-page {

    .home-page-container {
        width: 100%;
        .home-page-user-name {
            @include sideBarItemStyle(700, 24px, 32px, -0.02em, colors.$blue1);
        }

        .home-page-tiles-container {
            .home-page-tile-list {
                // max-width: 300px;
                // min-width: 150px;
                // width: 300px;
                padding: 0;
                margin-left: 10px;
                margin-top: 10px;
            }

            .home-page-tiles {
                height: 100%;
                min-height: 204px;
                display: grid;
                justify-content: start;
                align-items: center;
                cursor: pointer;
                background-color: $whiteColor;

                &:hover {
                    .home-page-tile {
                        svg {
                            fill: $whiteColor;

                            path {
                                stroke: $whiteColor;
                                fill: $whiteColor;
                            }
                        }

                        .tile-title,
                        .tile-subtitle {
                            color: $whiteColor
                        }
                    }
                }

                .home-page-tile {
                    display: grid;
                    grid-row-gap: 3rem;
                    margin-left: 1.5rem;
                    margin-right: 1.5rem;

                    .tile-title {
                        @include sideBarItemStyle(600, 24px, 28px);
                    }

                    .tile-subtitle {
                        @include sideBarItemStyle(400, 14px, 17px, -0.02em, #637583)
                    }
                }
            }

            @media screen and (width >=0px) and (width < 900px) {
                .home-page-tiles {
                    min-height: 150px;

                    .home-page-tile {
                        grid-row-gap: 1rem;
                        margin-left: 0.5rem;

                        .tile-title {
                            @include sideBarItemStyle(600, 18px, 20px);
                        }

                        .tile-subtitle {
                            @include sideBarItemStyle(400, 12px, 15px, -0.02em, #637583)
                        }
                    }
                }
            }

            .tile-defaultTile {
                border-bottom: 3px solid $blueColor;
                &:hover {
                    background-color: $blueColor;
                }
            }

            .tile-Census {
                border-bottom: 3px solid $tileRedColor;

                &:hover {
                    background-color: $tileRedColor;
                }
            }

            .tile-Staffing {
                border-bottom: 3px solid $tielYelloColor;

                &:hover {
                    background-color: $tielYelloColor;
                }
            }

            .tile-Finance {
                border-bottom: 3px solid $tilePictonBlueColor;

                &:hover {
                    background-color: $tilePictonBlueColor;
                }
            }

            .tile-Payroll {
                border-bottom: 3px solid $tileBlueColor;

                &:hover {
                    background-color: $tileBlueColor;
                }
            }

            .tile-Executive {
                border-bottom: 3px solid $tielPurpleColor;

                &:hover {
                    background-color: $tielPurpleColor;
                }
            }

            .tile-RevenueCycleManagement {
                border-bottom: 3px solid $tileAquamarineColor;

                &:hover {
                    background-color: $tileAquamarineColor;
                }
            }
        }
    }
}


.home-page-header-button {
    a {
        text-decoration: none;
    }

    .add-btn {
        border: 1px solid $blueColor;
        height: 35px;
        display: flex;
        width: 100%;
        justify-content: center;
        // padding: 0 10px;
        transition: 150ms ease-in-out;
        font-weight: 600;
        font-size: 16px;
        line-height: 14px;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        align-items: center;
        // padding-right: 13px;
        background-color: $blueColor;
        right: 0;
        box-sizing: border-box;
        border-radius: 4px;
        min-width: 128px;

        span,
        svg {
            color: #FFFFFF;
        }

        svg {
            color: #FFFFFF;
            font-size: 20px;
        }

        &:hover {
            cursor: pointer;
            opacity: 0.9;
            transform: scale(1.1);
        }

        &:active {
            cursor: pointer;
        }
    }
}



@media screen and (width >=0px) and (width < 600px) {
    .home-page .home-page-container .home-page-tiles-container .home-page-tile-list {
        width: 46%;
        margin-left: 12px;
        margin-top: 12px;
        flex-basis: 46% !important;
    }
}

@media screen and (width >=1530px) {
    .home-page .home-page-container .home-page-tiles-container .home-page-tile-list {
        width: 18.9%;
        margin-left: 17px !important;
        margin-top: 22px !important;
        flex-basis: 18.9%;
    }
}

@media screen and (width >=520px) and (width < 900px) {
    .home-page .home-page-container .home-page-tiles-container .home-page-tile-list {
        width: 31%;
        margin-left: 12px;
        margin-top: 12px;
        flex-basis: 31% !important;
    }
}

@media screen and (width >=900px)and (width <1530px) {
    .home-page .home-page-container .home-page-tiles-container .home-page-tile-list {
        width: 23.29%;
        margin-left: 18px !important;
        margin-top: 22px !important;
        flex-basis: 23.29% !important;
    }
}

@media screen and (width >=1530px)and (width <1769px) {
    .home-page .home-page-container .home-page-tiles-container .home-page-tile-list {
        width: 18.69%;
        margin-left: 18px !important;
        margin-top: 22px !important;
        flex-basis: 18.69% !important;
    }
}

@media screen and (width >=900px)and (width <1200px) {
    .home-page .home-page-container .home-page-tiles-container .home-page-tile-list {
        width: 31%;
        margin-left: 18px !important;
        margin-top: 22px !important;
        flex-basis: 31% !important;
    }
}