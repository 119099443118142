@use "/src/utils/colors";
@use '../Common/DialogRight.scss';
li.pd-menuitem {
  .pd-menuitem-inner {
    width: 100%;
    .pd-popover {
      position: absolute;
      display: none;
    }
    .db-name{
      color: var(--common-dark-gray, #374149);
      font-size: 14px;
      font-family: Open Sans, sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
  }

  &.pd-disabled {
    .pd-menuitem-inner {
      .db-name {
        opacity: 0.4;
      }
    }
    &:hover {
      // show the popover
      .pd-popover {
        display: flex;
        background-color: rgba(233, 194, 188, 0.8) !important;
        padding: 3px;
        z-index: 2;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        div {
          white-space: initial;
          line-height: 0.9em;
          font-size: 0.9em;
          margin: auto;
          text-align: center;
        }
      }
    }
  }
}
li.Mui-selected {background-color: #F1F4F7 !important;}
    .existing-container {
  
      input,
      input:focus {
        border: none;
        outline: none;
        color: colors.$blue5;
        padding-left: 10px;
        border-radius: 5px;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
        background-color: rgba(85, 136, 163, 0.2);
        height: 35px;
      }
  
      
  
      .small-error {
        font-size: 0.8em;
        color: #a04b5a;
        padding-top: 10px;
        padding-left: 5px;
      }
    
     
  .MuiInputBase-root {
  width: 100%;
  .MuiInputBase-input{

  background: none;
  border-radius: 4px;
border: 1px solid var(--neutral-08, #D6DADF);
.db-name{
  color: var(--common-dark-gray, #374149);
  font-size: 16px;
  font-family: Open Sans,sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
}
    }
}
.new-container, .existing-container{
  padding: 20px 0;
    min-height: 90px;
    .warning{
      color:#E2494F
    }
    input,
      input:focus {
        // border: none;
        outline: none;
        color: colors.$blue5;
        padding-left: 10px;
        height: 35px;
        background: none;
        border-radius: 4px;
        width: 96.7%;
        border: 1px solid var(--neutral-08, #D6DADF);
      }
    input::placeholder{
      color: var(--neutral-04, #758496);
      font-size: 14px;
      font-family: Open Sans,sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
  .select-lable{
    color: colors.$blue1;
    font-size: 15px;
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.32px;
    text-transform: none;
  }
  .small {
    color: var(--neutral-01, #58687C);
    font-size: 12px;
    font-family: Open Sans,sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.14px;
  }
  .MuiInputBase-root .MuiInputBase-input{

    
  
  .db-name{
    color: var(--common-dark-gray, #374149);
    font-size: 16px;
    font-family: Open Sans,sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }
  }
}