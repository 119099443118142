@use "/src/utils/colors";

#login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
    to bottom,
    #00334e,
    colors.$blue5 55%,
    colors.$blue5
  );
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .apex-logo {
    width: 300px;
    height: auto;
    margin-bottom: 20px;
  }

  #login-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 300px;
    border-radius: 10px;
    border: solid 2px colors.$blue4;

    font-family: Rubik;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 1.4px;
    text-align: center;
    color: #cb9853;
    padding-bottom: 10px;

    > div:first-of-type {
      margin-bottom: 8px;
      font-size: 1.1em;
    }

    button {
      position: absolute;
      bottom: 0;
      transform: translateY(50%);
      width: 148px;
      height: 40px;
      margin: 0;
      border-radius: 100px;
      box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.3);
      background-color: #e8e8e8;
      border-color: unset;
      font-family: Rubik;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.5;
      letter-spacing: 1.4px;
      text-align: center;
      color: colors.$blue1;
      border-image: unset;
      border-style: unset;
      border-width: unset;
      transition: 100ms ease-in-out;

      &:hover {
        cursor: pointer;
        background-color: colors.$blue4;
      }
    }
  }
}
