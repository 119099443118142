@use "/src/utils/colors";
@use "../../../styles/Variables-Functions.scss" as *;

.dashboard-nav-right {
    // min-height: 48px;
    padding-right: 0.5em;

    button {
        // min-height: 32px;
        // min-width: 46px;
        padding: 0;
        // display: flex;
        height: 27px;
        // padding: 8px 12px;
        align-items: center;
        gap: 8px;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid var(--neutral-10, #F1F4F7);
        background: #FFF;
        
    }
    .no-bradius{
        border-radius: 0;
    }
    .three-dots{
        border-radius: 0px 4px 4px 0px;
    }
    svg{
        // height: 27px;
        height: 15px;
        width: 35px;
    }

    .dasboard-threedot-icon {
        svg {
            path:first-child {
                stroke: #165CE8;
            }
        }
    }
    
}

.item-name{
    color: colors.$blue1;
    font-size: 14px;
    font-family: Open Sans, sans-serif;
    line-height: 14px;
    letter-spacing: -0.14px;
    span{
        font-size: 0.8rem !important;
    }
}
.favorite-icon {
    svg {
        path {
            fill: #F8C45A;
            stroke: #F8C45A;
        }
    }
}
@media screen and (max-width: 600px) {
    .menu-item{
        min-height: auto !important;
    }
}